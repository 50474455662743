import LatexInput from "../../common/LatexInput";
import React from "react";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {getLetter} from "../../../utils";

export default function QuestionAnswer (props) {

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={8} sm={8}>
                    <LatexInput
                        required
                        name={"answer" + props.i}
                        label=""
                        fullWidth
                        autoComplete=""
                        placeholder={"Answer "+getLetter(props.i-1)}
                        value={props.question["answer"+props.i]}
                        error={props.question["answer"+props.i+"_error"]}
                        onChange={(value) => props.onAnswerChange(props.index, props.i, value)}
                    />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <FormControlLabel style={{paddingLeft: 1+"em"}}
                        control={<Switch onChange={() => props.onCorrectAnswer(props.index,props.i)} checked={props.question.correctAnswer===props.i}/>}
                        label={props.question.correctAnswer===props.i ? "Correct answer" : ""}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export const MemoizedQuestionAnswers = React.memo(QuestionAnswer);