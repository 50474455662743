import React, {Component, useEffect, useState} from "react";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useAxiosInstance from "../../../api/axiosApi";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import ErrorMsg from "../../common/ErrorMsg";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    btn: {
        marginTop: 2+"em",
        marginBottom: 2+"em"
    },
    instructions: {
        listStyle: "disc"
    }

}));

export default function StartQuizz(props) {

    let history = useHistory();

    const [quizz, setQuizz] = useState({});
    const [studentNumber, setStudentNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [notStarted, setNotStarted] = useState(false);
    const [hasStopped, setHasStopped] = useState(false);

    const axiosInstance = useAxiosInstance(props);

    useEffect(() => {
        //check if there is any existing session
        let session_id = localStorage.getItem("quizz_" + props.match.params.code);
        if (session_id) {
            history.push("/run_quizz/"+props.match.params.code);
            return;
        }
        axiosInstance.get('/api/quizz/_start/'+props.match.params.code, {headers:{"Authorization":null}}).then(response => {
            if (response.status === 200 && response.data.questions) {
                setQuizz(response.data);
                setIsLoading(false);
            }
        }).catch(error => {
            if (error.response.data === "This quizz has not yet started.") {
                setNotStarted(true);
            } else if (error.response.data === "This quizz has ended.") {
                setHasStopped(true);
            }
        });
    }, []);

    const start = (event) => {
        if (validate()) {
            console.log("start");

            let params = {headers:{"Authorization":null}};
            let session_id = localStorage.getItem("quizz_" + quizz.code);
            let request_data = {};
            if (!quizz.anonymous) {
                request_data["studentNumber"] = studentNumber;
                request_data["firstName"] = firstName;
                request_data["lastName"] = lastName;
            }
            let session_id_params = "";
            axiosInstance.post('/api/quizz/response/_start/' + quizz.code+session_id_params, request_data, params).then(res => {
                localStorage.setItem('quizz_' + quizz.code, res.data.response.session_id);
                history.push("/run_quizz/"+quizz.code);
            })
        }
    };


    const validate = () =>  {
        let errors = {};
        if (!quizz.anonymous) {
            if (!firstName) errors["firstName"] = "Please enter your first name.";
            if (!lastName) errors["lastName"] = "Please enter your last name.";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onFieldChange = (fct, value) => {
        setErrors({});
        fct(value);
    };

    const classes = useStyles();


    if (notStarted) {
        return  <ErrorMsg>This quizz has not yet started.</ErrorMsg>;
    }

    if (hasStopped) {
        return  <ErrorMsg>This quizz has ended. You cannot participate anymore.</ErrorMsg>;
    }

    if (isLoading) {
        return  <Box>Loading...</Box>;
    }

    return (
        <Container maxWidth="lg" component="main" style={{marginTop: 2+"em"}}>
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    {quizz.title}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {quizz.description}
                </Typography>
                <Grid container justify="space-between" >
                    <Grid item sm={12} style={{textAlign:"center"}}>
                        <Typography variant="h5">
                            <CheckCircleOutlineIcon/> {quizz.questions.length} questions
                        </Typography>
                        <Typography variant="h5">
                            <AccessTimeIcon/> {quizz.duration} minutes
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>

                        <Typography variant="subtitle1" gutterBottom color="secondary" style={{marginTop: 1+"em"}}>
                            Instructions
                        </Typography>
                        <Paper elevation={3} className={classes.paper}>
                            <ul className={classes.instructions}>
                                <li>Only one answer is correct per question</li>
                                <li>Time starts when you click the button here below.</li>
                                <li>Once the time ends, you can still submit your quizz but answers cannot be changed anymore.</li>
                                <li>You can take questions in any order.</li>
                            </ul>
                        </Paper>

                        {
                            (!quizz.anonymous) ?

                                <Grid container justify="space-between"
                                      style={{marginTop: 2 + "em", marginBottom: 2 + "em"}}>
                                    <Grid item sm={12}>
                                        <TextField
                                            label="Student number"
                                            value={studentNumber}
                                            placeholder="123456"
                                            onChange={event => onFieldChange(setStudentNumber, event.target.value)}
                                            error={errors.studentNumber ? true : false}
                                            helperText={errors.studentNumber}
                                        />
                                    </Grid>
                                    <Grid item sm={5}>
                                        <TextField
                                            label="First name"
                                            value={firstName}
                                            fullWidth
                                            placeholder="John"
                                            onChange={event => onFieldChange(setFirstName, event.target.value)}
                                            error={errors.firstName ? true : false}
                                            helperText={errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item sm={5}>
                                        <TextField
                                            label="Last name"
                                            value={lastName}
                                            fullWidth
                                            placeholder="Travolta"
                                            error={errors.lastName ? true : false}
                                            helperText={errors.lastName}
                                            onChange={event => onFieldChange(setLastName, event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                : null
                        }

                        <Button className={classes.btn} variant="contained" color="primary" onClick={start}>Start</Button>

                    </Grid>

                </Grid>
            </Paper>
        </Container>
    );

}

