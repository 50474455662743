import useAxiosInstance from "../../api/axiosApi";
import React, {useEffect, useReducer, useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {LEVELS_URL, MARKET_URL, THEMES_URL, TYPES_URL} from "../../constants";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import {MemoizedQuestionCard} from "../quizz/create/QuestionCard";
import questionsReducer from "../quizz/create/QuestionsReducer";
import {emptyQuestion} from "../quizz/create/CreateQuizz";
import {validate} from "json-schema";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    }}));

export default function Propose (props) {

    const axiosInstance = useAxiosInstance(props);
    const[description, setDescription] = useState("");
    const[tags, setTags] = useState("");
    const[theme, setTheme] = useState(0);
    const[level, setLevel] = useState(0);
    const[type, setType] = useState(0);
    const[themesList, setThemesList] = useState([]);
    const[levelsList, setLevelsList] = useState([]);
    const[typesList, setTypesList] = useState([]);
    const[errors, setErrors] = useState([]);
    const[edit, setEdit] = useState(false);
    const [questions, questionsDispatch] = useReducer(questionsReducer, [emptyQuestion()]);


    const [loading, setLoading] = React.useState(true);


    const getThemes = () => {
        let themes = [];
        return axiosInstance.get(THEMES_URL).then(res => {
            res.data.map((theme) => {
                themes.push(

                    {
                        "id": theme.id,
                        "name": theme.name,
                    }
                );

            });
            setThemesList(themes);
        });
    };

    const getLevels = () => {
        let levels = [];
        return axiosInstance.get(LEVELS_URL).then(res => {
            res.data.map((level) => {
                levels.push(

                    {
                        "id": level.id,
                        "name": level.name,
                    }
                );

            });
            setLevelsList(levels);
        });
    };

    const getTypes = () => {
        let types = [];
        return axiosInstance.get(TYPES_URL).then(res => {
            res.data.map((type) => {
                types.push(

                    {
                        "id": type.id,
                        "name": type.name,
                    }
                );

            });
            setTypesList(types);
        });
    };


    useEffect( () => {
        const themesPromise = getThemes();
        const levelsPromise = getLevels();
        const typesPromise = getTypes();
        Promise.all([themesPromise, levelsPromise, typesPromise]).then(() => {
            if (props.id) {
                setEdit(true);
                axiosInstance.get(MARKET_URL + "/" + props.id).then(res => {
                    setDescription(res.data.description);
                    setTags(res.data.tags);
                    setTheme(res.data.theme);
                    setLevel(res.data.level);
                    setType(res.data.type);
                    let questions = [
                        {
                            question: res.data.text,
                            answer1: res.data.answer1,
                            answer2: res.data.answer2,
                            answer3: res.data.answer3,
                            answer4: res.data.answer4,
                            smallImageUrl: res.data.smallImageUrl,
                            bigImageUrl: res.data.bigImageUrl,
                            correctAnswer: res.data.correct_answer,
                            explanation: res.data.explanation,
                            explanationSmallImageUrl: res.data.explanationSmallImageUrl,
                            explanationBigImageUrl: res.data.explanationBigImageUrl
                        }
                    ];
                    questionsDispatch({
                        type: "SET_QUESTIONS",
                        questions: questions
                    });
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        });
    }, []);

    const handleSubmit = async (event) => {

        if (validate(questions, questionsDispatch)) {

            const data = {
                description: description,
                tags: tags,
                theme: theme>0 ? theme : undefined,
                level: level>0 ? level : undefined,
                type: type>0 ? type : undefined,
                text: questions[0].question,
                answer1: questions[0].answer1,
                answer2: questions[0].answer2,
                answer3: questions[0].answer3,
                answer4: questions[0].answer4,
                smallImageUrl: questions[0].smallImageUrl,
                bigImageUrl: questions[0].bigImageUrl,
                correct_answer: questions[0].correctAnswer,
                explanation: questions[0].explanation,
                explanationSmallImageUrl: questions[0].explanationSmallImageUrl,
                explanationBigImageUrl: questions[0].explanationBigImageUrl
            };

            if (!edit) {
                axiosInstance.post('/api/market/quizz', data)
                    .then(response => {
                        props.history.push("/market/confirm");
                    })
                    .catch(error => {
                        setErrors(error.response.data);
                    });
            }
            else {
                axiosInstance.patch('/api/market/quizz/'+props.id, data)
                    .then(response => {
                        props.onClose();
                    })
                    .catch(error => {
                        setErrors(error.response.data);
                    });
            }
        }
    };

    const classes = useStyles();

    return (


        <main className={classes.layout}>

            <Grid container spacing={3}>
                { errors.questions ?
                    <Grid item xs={12} sm={12} style={{paddingTop:2+"em"}}>
                        <Alert elevation={6} variant="filled" severity="error">
                            Your quizz questions are invalid.
                        </Alert>
                    </Grid>
                    :
                    <React.Fragment/>
                }

                <Grid item xs={12} sm={12} style={{paddingTop:2+"em"}}>
                    <TextField
                        id="description"
                        name="description"
                        required
                        placeholder="Enter a description of your quizz"
                        label="Description"
                        value={description}
                        variant="outlined"
                        fullWidth
                        error={ errors.description ? true : false}
                        helperText={errors.description}
                        onChange={(event) => setDescription(event.target.value)}
                        autoComplete=""
                    />
                </Grid>
                <Grid item xs={12} sm={12} style={{paddingTop:1+"em"}}>
                    <TextField
                        id="tags"
                        name="tags"
                        required
                        placeholder="Enter tags separated by a comma (,) to facilitate search"
                        label="Tags"
                        value={tags}
                        variant="outlined"
                        fullWidth
                        error={ errors.tags ? true : false}
                        helperText={errors.tags}
                        onChange={(event) => setTags(event.target.value)}
                        autoComplete=""
                    />
                </Grid>
                <Grid item xs={12} sm={12} style={{paddingTop:1+"em"}}>
                    <InputLabel id="level-select-label">Level</InputLabel>

                    <Select
                        labelId="level-select-label"
                        value={level}
                        fullWidth
                        variant="outlined"
                        onChange={(event) => setLevel(event.target.value)}
                    >
                        <MenuItem key={0} value={0}>0 - Not specified</MenuItem>
                        {
                            levelsList.map((level, index) => (
                                <MenuItem key={level.id} value={level.id}>{level.name}</MenuItem>
                            ))
                        }

                    </Select>
                </Grid>
                <Grid item xs={12} sm={12} style={{paddingTop:1+"em"}}>
                    <InputLabel id="type-select-label">Type</InputLabel>

                    <Select
                        labelId="type-select-label"
                        value={type}
                        fullWidth
                        variant="outlined"
                        onChange={(event) => setType(event.target.value)}
                    >
                        <MenuItem key={0} value={0}>0 - Not specified</MenuItem>
                        {
                            typesList.map((type, index) => (
                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                            ))
                        }

                    </Select>
                </Grid>
                <Grid item xs={12} sm={12} style={{paddingTop:1+"em"}}>
                    <InputLabel id="theme-select-label">Theme</InputLabel>

                    <Select
                        labelId="theme-select-label"
                        value={theme}
                        fullWidth
                        variant="outlined"
                        onChange={(event) => setTheme(event.target.value)}
                    >
                        <MenuItem key={0} value={0}>0 - Not specified</MenuItem>
                        {
                            themesList.map((theme, index) => (
                                <MenuItem key={theme.id} value={theme.id}>{theme.name}</MenuItem>
                            ))
                        }

                    </Select>
                </Grid>

            </Grid>

            {
                questions.map((question, index) => (
                    <div key={question.id}>
                        <MemoizedQuestionCard explanation={true} index={index} question={question} dispatcher={questionsDispatch}
                                              multiple={false} onCardFocus={() => void 0} selectedCard={0}/>
                    </div>
                ))
            }

            <Grid container justify="space-between" style={{marginTop: 2 + "em"}}>
                <Grid item xs={9}>

                </Grid>
                <Grid item xs={3}>
                    <div align="right">
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </main>
    )


}