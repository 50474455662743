import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import useAxiosInstance from "../../api/axiosApi";
import {FAQ_CATEGORIES_URL, LATEX_CATEGORIES_URL} from "../../constants";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {convertCarriageReturns} from "../../utils";


const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function FAQ (props) {

    const axiosInstance = useAxiosInstance(props);
    const [data, setData] = React.useState([]);

    const classes = useStyles();

    useEffect( () => {
        let data = [];
        axiosInstance.get(FAQ_CATEGORIES_URL).then(res => {
            setData(res.data);
        });
    }, []);

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    Questions and answers about TexQuiz.com
                </Typography>
                <p>
                    Do you have any question about our service? You will find here below answers to the most popular questions.
                </p>

                {
                    data.map((category, index) => (
                        <React.Fragment key={index}>
                            <Typography variant="subtitle1" color="primary" style={{marginBottom: 1 + "em", marginTop: 2+"em"}}>
                                {category.name}
                            </Typography>
                            {
                                category.entries.map((entry, index) => (

                                    <Accordion>

                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-label="Expand"
                                            aria-controls="additional-actions1-content"
                                            id="additional-actions1-header"
                                            className={classes.faqTitle}

                                        >
                                            <Typography className={classes.heading}>{entry.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <div dangerouslySetInnerHTML={{__html: convertCarriageReturns(entry.text)}}/>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                        </React.Fragment>
                    ))
                }

            </Paper>
        </Container>
    );

}