import React from "react";

const ProgressBar = (props) => {
  const { bgColor, fillColor, completed, width, label } = props;

  const containerStyles = {
    height: 30,
    width: width,
    opacity:0.6,
    backgroundColor: bgColor,
    marginBottom: 1+"em"
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: fillColor,
    transition: 'width 1s ease-in-out',
    textAlign: 'left',
  };

  const labelStyles = {
    paddingTop: 5,
    paddingLeft: 5,
    color: 'white',
  };

  const getLabel = () => {
      if (label) return label;
      else return completed+"%";
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{getLabel()}</span>
      </div>
    </div>
  );
};

export default ProgressBar;