import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import Obfuscate from 'react-obfuscate'

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function FAQ (props) {


    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    Legal notice
                </Typography>
                <p>
                    Texquiz is a web application of
                </p>

                <p>
                    Promath Sàrl<br/>
                    Chemin des Planches 12<br/>
                    CH- 1066 Epalinges<br/><br/>
                    Responsible person: Jean-Pierre Favre<br/>
                    Contact: +41 79 445 80 64<br/>
                    Email: <Obfuscate email="favre@promath.ch"/><br/><br/>
                    Commercial register (UID): CHE-105.300.065
                </p>

            </Paper>
        </Container>
    );

}