import React, {Component, useContext, useState} from "react";
import Container from '@material-ui/core/Container';
import '../../css/plans.css';
import PlansCards from "./PlansCards";
import TiersData from '../../data/plansData.js'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {AuthContext} from "../contexts/AuthContext";
import { Link as RouterLink } from 'react-router-dom';

export default function Plans (props) {

    const auth = useContext(AuthContext);

    return (

        <Container maxWidth="md" component="main" style={{marginTop: 2+"em"}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <PlansCards tiers={TiersData()}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {!auth.state.isAuthenticated ?
                        <Button component={RouterLink} variant="contained" color="primary" to="/signup">
                            Create an account now
                        </Button>
                        :
                        <Button component={RouterLink} variant="contained" color="primary" to="/billing/plan">
                            Get the Pro version
                        </Button>
                    }
                </Grid>
            </Grid>
        </Container>

    );

}