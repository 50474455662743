import React, {useState, useMemo, useCallback} from "react";
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import SimpleImageDialog from "./SimpleImageDialog";
import {ButtonBase} from "@material-ui/core";

export default function PreviewImage (props) {

    const [open, setOpen] = React.useState(false);

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };


    return (
        <div>
            {

                props.smallImageUrl ? (
                    <React.Fragment>
                        <ButtonBase  onClick={handleClickOpen}>
                        <div>
                            <img src={props.smallImageUrl}/><br/>
                            <small>Click to enlarge</small>
                        </div>
                            </ButtonBase>

                        <SimpleImageDialog image={props.bigImageUrl} open={open} onClose={handleClose}/>
                    </React.Fragment>
                ) : <React.Fragment/>

            }
        </div>
    )
}