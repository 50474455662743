import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useAxiosInstance from "../../api/axiosApi";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Typography from "@material-ui/core/Typography";
import {add, format} from 'date-fns'


const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    formElem: {
        marginTop: '1.5em'
    }
}));

export default function CopyQuizz (props) {

    let history = useHistory();

    const axiosInstance = useAxiosInstance(props);

    const [sourceTag, setSourceTag] = useState("");
    const [targetTag, setTargetTag] = useState("");
    const [responseData, setResponseData] = useState(null);

    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);


 

    const validate = () => {
        let errors = {};
        if (sourceTag.length < 1) errors["sourceTag"] = "Please enter a source tag";
        if (targetTag.length < 1) errors["targetTag"] = "Please enter a target tag";

        setErrors(errors);
        return !Object.keys(errors).length>0
    };

    const copyToClipboard = async (event) => {
        navigator.clipboard.writeText(responseData.join("\n"));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {

            let submitData = {
                source_tag: sourceTag,
                target_tag: targetTag
            };

            try {
                setLoading(true);
                const response = await axiosInstance.post('/api/admin/copy_market_quizz', submitData);
                setResponseData(response.data);
            } catch (error) {
                setErrors(error.response.data);
            }
            setLoading(false);

        }
    };


    const classes = useStyles();

    return (
        <Container maxWidth="md">
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" color="primary" gutterBottom>
                    Copy market quizz
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                    <Grid container justify="space-between" style={{marginTop: 1 + "em"}}>

                        <Grid item xs={12} sm={12} className={classes.formElem}>
                            <TextField
                                label="Source tag"
                                value={sourceTag}
                                onChange={event => setSourceTag(event.target.value)}
                                error={ errors.sourceTag ? true : false}
                                helperText={errors.sourceTag}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} className={classes.formElem}>
                            <TextField
                                label="Target tag"
                                value={targetTag}
                                onChange={event => setTargetTag(event.target.value)}
                                error={ errors.targetTag ? true : false}
                                helperText={errors.targetTag}
                            />
                        </Grid>                     
                    </Grid>
                </form>
                <Button disabled={isLoading} variant="contained" color="primary" onClick={handleSubmit} style={{marginTop: 1+ "em"}}>
                    Copy
                </Button>
                <Box style={{marginTop: 2+ "em"}} hidden={!isLoading}>Loading...</Box>
                { responseData ?
                    <React.Fragment>
                      <Box>Done</Box>
                    </React.Fragment>
                    : null
                }

            </Paper>
        </Container>
    )


}
