import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Grid from "@material-ui/core/Grid";
import ConfigContext from "../../contexts/ConfigContext";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuizzDetailShare(props) {

    const classes = useStyles();

    const { config } = useContext(ConfigContext);

    return (
        <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>

            <Button variant="contained" color="primary" onClick={props.onClose} aria-label="close">
                Close
            </Button>

            <Grid container direction="row" justify="center" alignItems="flex-start" style={{marginTop: 2 + "em", marginBottom: 2+"em"}}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Typography variant="h2" component="h2" style={{marginBottom:2+"em"}}>
                        {props.quizz.description}
                    </Typography>
                    <img src={config.FRONT_BACK_BASE_URL+"image/quizz/qrcode/"+props.quizz.code+"/10"}/><br/><br/>
                    <Typography gutterBottom variant="h5" component="h2">
                        Code: {props.quizz.code}<br/>
                        <Link href={config.FRONT_BASE_URL+"q/"+props.quizz.code}>{config.FRONT_BASE_URL+"q/"+props.quizz.code}</Link>
                    </Typography>
                </Grid>

            </Grid>


        </Dialog>
    )
}