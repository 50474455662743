import React, {Component, useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import LinearProgress from "@material-ui/core/LinearProgress";
import useAxiosInstance from "../../api/axiosApi";
import {AuthContext} from "../contexts/AuthContext";
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},

}));

export default function BillingConfirmation(props) {

    const axiosInstance = useAxiosInstance(props);
    const auth = useContext(AuthContext);

    const [activated, setActivated] = useState(false);
    const [retries, setRetries] = useState(0);

    useEffect(() => {
        if (retries < 20 && !activated) {
            const timer = setTimeout(() => {
                axiosInstance.get('/api/license?active=true').then(response => {
                    if (response.data.length > 0) {
                        setActivated(true);
                        axiosInstance.get('/auth/users/me').then(res => {
                            auth.dispatch({
                                type: "LOGIN",
                                user: res.data,
                                token: auth.state.token
                            });
                        });

                    }
                    setRetries(retries + 1);
                });
            }, 2000);
            return () => clearTimeout(timer);
        }
    });

    const classes = useStyles();


    return (
        <Container maxWidth="sm">
            <Paper elevation={3} className={classes.paper}>
                { !activated ? (
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom color="primary">
                                Your new plan is being activated
                            </Typography>
                            <p>We are processing your payment. Please wait a few seconds.</p>
                            <LinearProgress />

                        </React.Fragment>
                    )
                    : (
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom color="primary">
                                Your new plan is now activated
                            </Typography>
                            <p className="lead">Thank you for your payment. You can now access all the new features of your plan.</p>
                            <Button component={RouterLink} variant="contained" color="primary" to="/quizz" style={{marginTop: 2 + "em"}}>
                                Go to My Quizzes
                            </Button>
                        </React.Fragment>
                    )
                }
            </Paper>
        </Container>
    )
}