import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import React from "react";

export default function SimpleDialog(props) {

    return (
        <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open}>
            <img src={props.image} style={{maxWidth:"100%"}}/>
        </Dialog>
    );
}