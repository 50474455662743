import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    questionBtn: {
        minWidth: 40+"px",
        marginLeft: 1+"em",
        marginBottom: 1+"em"
    }

}));

export default function QuestionsButtons(props) {

    const classes = useStyles();


    const questionsBtn = () => {
        if (props.questions) {
            let content = [];
            for (let i = 0; i < props.questions.length; i++) {
                let answer = props.answers[props.questions[i].id];
                content.push(
                    <Button className={classes.questionBtn} variant={answer>=0 ? "contained" : "outlined"} size="small"
                            onClick={() => props.toQuestion(i)} color="secondary">{i + 1}</Button>
                )
            }
            return content;
        }
        else return (<div/>)
    };

    return (
        <div>
            {questionsBtn()}
        </div>
    )
}
