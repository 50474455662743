import makeStyles from "@material-ui/core/styles/makeStyles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {convertCarriageReturns} from "../../utils";
import {InlineTex} from "react-tex";
import useAxiosInstance from "../../api/axiosApi";
import {LATEX_CATEGORIES_URL} from "../../constants";

const useStyles = makeStyles({
    drawer: {
        width: 600,
        padding: 1+"em"
    },
    fullList: {
        width: 'auto',
    },
    table: {
        borderCollapse: "collapse",
        width: "100%",
        '& td, & th': {
            border: "1px solid #000000",
            padding: "5px"
        },
        '& th': {
            textAlign: "left"
        }
    }
});

export function LatexHelpDrawer(props) {

    const classes = useStyles();
    const axiosInstance = useAxiosInstance(props);
    const [data, setData] = React.useState([]);

    const toggleDrawer = () => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    };

    useEffect( () => {
        let data = [];
        axiosInstance.get(LATEX_CATEGORIES_URL).then(res => {
            setData(res.data);
        });
    }, []);

    return (
        <SwipeableDrawer
            anchor="right"
            open={props.open}
            onOpen={() => void 0}
            onClose={() => props.onClose()}
        >
            <div className={classes.drawer}>
                <Grid container justify="space-between" style={{marginTop: 1 + "em"}}>
                    <Grid item sm={12}>
                        <Typography variant="h6" color="primary">
                            Entering a LaTeX formula.
                        </Typography>
                        Surround your formula with the $ sign. Eg: $x^2$
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant="h6" color="primary" style={{marginTop: 1+"em"}}>
                            Supported functions
                        </Typography>
                        This is a list of TeX functions supported by TexQuiz. It is sorted into logical groups.
                    </Grid>
                    <Grid item sm={12}>
                        {
                            data.map((category, index) => (
                                <React.Fragment key={index}>
                                    <Typography variant="subtitle1" color="primary" style={{marginTop: 1+"em"}}>
                                        {category.name}
                                    </Typography>
                                    <table className={classes.table}>
                                        <thead>
                                        <tr>
                                            <th>Symbol/Function</th>
                                            <th>Example</th>
                                            <th>Rendered</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            category.commands.map((command, index) => (

                                                <tr key={command.id}>
                                                    <td>{command.symbol}</td>
                                                    <td><div dangerouslySetInnerHTML={{__html: convertCarriageReturns(command.example)}} /></td>
                                                    <td><InlineTex texContent={convertCarriageReturns("$"+command.example+"$")} texSeperator="${1}"/></td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            ))
                        }
                    </Grid>
                </Grid>
            </div>
        </SwipeableDrawer>
    )
}