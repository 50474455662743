import React, {useState, useMemo, useCallback, useContext, useEffect} from "react";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CircularProgress from '@material-ui/core/CircularProgress';

import {useDropzone} from 'react-dropzone';
import useAxiosInstance from "../../api/axiosApi";
import SimpleImageDialog from "./SimpleImageDialog";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PasteContext from "../contexts/PasteContext";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function dispatchEvt(node, type, data) {
    var event = new Event(type, { bubbles: true});
    Object.assign(event, data);
    node.dispatchEvent(event);
}

export default function UploadImage (props) {

    const axiosInstance = useAxiosInstance(props);

    const [open, setOpen] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const pasteContext = useContext(PasteContext);

    const onDrop = useCallback((acceptedFiles, rejectedFiles, event) => {
        let formData = new FormData();

        for (var i = 0; i < acceptedFiles.length; i++) {
            let file = acceptedFiles[i];
            formData.append('file', file);
        }

        setLoading(true);
        axiosInstance.post('/api/upload_files', formData).then(response => {
            setLoading(false);
            props.onImageChange(response.data.smallImageUrl, response.data.bigImageUrl);
        })
            .catch(error => {
                setErrorOpen(true);
                setLoading(false);
            });

    });


    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        rootRef,
        inputRef,
    } = useDropzone({accept: 'image/*', onDrop: onDrop});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    useEffect( () => {
        if (pasteContext.state && pasteContext.state.pasteImage && props.hasFocus) {
            dispatchEvt(inputRef.current, 'drop', pasteContext.state.pasteImage);
            pasteContext.dispatch(undefined);
        }
    }, [pasteContext]);

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    return (
        <div>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Cannot upload this picture. An error occurred.
                </Alert>
            </Snackbar>
            {
                loading ? (
                        <div {...getRootProps({style})}>
                            <CircularProgress />
                        </div>
                    ) :
                    props.smallImageUrl ? (
                            <React.Fragment>
                                <div {...getRootProps({style})}>
                                    <input {...getInputProps()} />
                                    <img src={props.smallImageUrl}/>
                                </div>
                                <IconButton color="primary" aria-label="Zoom" onClick={handleClickOpen}>
                                    <ZoomInIcon/>
                                </IconButton>
                                <SimpleImageDialog image={props.bigImageUrl} open={open} onClose={handleClose}/>
                            </React.Fragment>
                        )
                        : (
                            <React.Fragment>
                                <div {...getRootProps({style})}>
                                    <input {...getInputProps()} />
                                    <p>Drop or paste some files here, or click to select files</p>
                                </div>
                            </React.Fragment>
                        )
            }
        </div>
    )
}

export const MemoizedUploadImage = React.memo(UploadImage);