import useAxiosInstance from "../../api/axiosApi";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from '@material-ui/core/FormHelperText';

export default function BillingCheckout (props) {

    const salutations = {"Mr": "Monsieur", "Ms": "Madame"};
    const countries = {"CH": "Switzerland", "FR": "France", "IT": "Italy", "DE": "Germany", "ES": "Spain", "PT": "Portugual", "LU": "Luxemburg", "BE": "Belgium"};
    const useStyles = makeStyles((theme) => ({

        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            }
        },
        productSummary: {
            margin: theme.spacing(3)
        },
        productTable: {
            width:"100%",
            marginTop: 2+"em",
            "& td": {
                height: 40+"px"
            }
        },
        formElem: {
            marginTop: '1.5em'
        }
    }));

    const classes = useStyles();

    const axiosInstance = useAxiosInstance(props);

    const [transactionId, setTransactionId] = useState(undefined);
    const [salutation, setSalutation] = useState("Mr");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [street, setStreet] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("CH");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
    });

    const validate = () => {
        let errors = {};
        if (!firstName) errors["firstName"] = "Please enter a first name";
        if (!lastName) errors["lastName"] = "Please enter a last name";
        if (!street) errors["street"] = "Please enter a street";
        if (!postalCode) errors["postalCode"] = "Please enter a postal code";
        if (!city) errors["city"] = "Please enter a city";
        if (!termsAccepted) errors["termsAccepted"] = "Please accept the terms and conditions";
        setErrors(errors);
        return !Object.keys(errors).length>0
    };


    const submitForm = (event) => {
        event.preventDefault();
        if (validate()) {
            axiosInstance.get('/api/billing/transaction', {
                params: {
                    currency: props.location.state.currency,
                    numberLicenses: 1,
                    salutation: salutation,
                    familyName: lastName,
                    givenName: firstName,
                    street: street,
                    postCode: postalCode,
                    city: city,
                    country: country,
                }, headers: {"Authorization": null}
            }).then(response => {
                //redirect to payment page
                window.location.href = response.data.url;
            });
        }
    };

    return (
        <Container maxWidth="md" component="main" style={{marginTop: 2+"em"}}>
            <Paper className={classes.paper}>
                <Grid container justify="space-between" style={{marginTop: 1 + "em"}}>
                    <Grid item xs={12} sm={7}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Checkout
                        </Typography>
                        <Grid container justify="space-between" style={{marginTop: 1 + "em"}}>
                            <Grid item xs={12} sm={12} className={classes.formElem}>
                                <InputLabel id="salutation-label" shrink>Title</InputLabel>
                                <Select labelId="salutation-label" fullWidth value={salutation}
                                        onChange={(event) => setSalutation(event.target.value)}
                                >
                                    {
                                        Object.keys(salutations).map((key, index) => (
                                            <MenuItem key={key} value={key}>{salutations[key]}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </Grid>
                            <Grid item xs={5} sm={5} className={classes.formElem}>
                                <TextField
                                    label="First name"
                                    value={firstName}
                                    fullWidth
                                    onChange={event => setFirstName(event.target.value)}
                                    error={ errors.firstName ? true : false}
                                    helperText={errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={5} sm={5} className={classes.formElem}>
                                <TextField
                                    label="Last name"
                                    value={lastName}
                                    fullWidth
                                    error={ errors.lastName ? true : false}
                                    helperText={errors.lastName}
                                    onChange={event => setLastName(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} className={classes.formElem}>
                                <TextField
                                    label="Street"
                                    value={street}
                                    fullWidth
                                    onChange={event => setStreet(event.target.value)}
                                    error={ errors.street ? true : false}
                                    helperText={errors.street}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} className={classes.formElem}>
                                <TextField
                                    label="Postal code"
                                    value={postalCode}
                                    fullWidth
                                    error={ errors.postalCode ? true : false}
                                    helperText={errors.postalCode}
                                    onChange={event => setPostalCode(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={7} sm={7} className={classes.formElem}>
                                <TextField
                                    label="City"
                                    value={city}
                                    fullWidth
                                    error={ errors.city ? true : false}
                                    helperText={errors.city}
                                    onChange={event => setCity(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} className={classes.formElem}>
                                <InputLabel id="country-label" shrink>Country</InputLabel>
                                <Select
                                    labelId="country-label"
                                    label="Country"
                                    value={country}
                                    fullWidth
                                    onChange={(event) => setCountry(event.target.value)}
                                >
                                    {
                                        Object.keys(countries).map((key, index) => (
                                            <MenuItem key={key} value={key}>{countries[key]}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12} className={classes.formElem}>
                                <FormControl required error={errors.termsAccepted}>
                                    <FormControlLabel
                                        label={<label>With this order, I accept the <Link component={RouterLink} to="/terms" target="_new">General terms and conditions</Link></label>}
                                        control={
                                            <Checkbox
                                                checked={termsAccepted}
                                                onChange={event => setTermsAccepted(event.target.checked)}
                                                color="primary"
                                            />
                                        }

                                    />
                                    <FormHelperText>{errors.termsAccepted}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.productSummary}>
                        <Typography variant="h6" color="secondary" gutterBottom>
                            Product summary
                        </Typography>
                        <table className={classes.productTable}>
                            <tr>
                                <td colSpan={2}><b>Pro plan</b></td>
                            </tr>
                            <tr>
                                <td>License type</td>
                                <td>Personal</td>
                            </tr>
                            <tr>
                                <td>Price</td>
                                <td>49 {props.location.state.currency}</td>
                            </tr>
                            <tr>
                                <td>Valid until</td>
                                <td>10.10.2020</td>
                            </tr>
                        </table>
                    </Grid>
                </Grid>
                <Button color="primary" variant="contained" onClick={submitForm} style={{marginTop: "2em"}}>
                    Proceed to payment
                </Button>
                <br/>
                <img style={{clear:"both", marginTop: 2+"em", marginLeft:"auto", height: "40px"}} src="/texquiz_modes_paiement.png"/>
            </Paper>
        </Container>
    )
}
