const quizzReducer = (state, action) => {
    let questions = [...state.questions];
    switch (action.type) {
        case "UPDATE_PROPERTY":
            return {
                ...state,
                [action.key]: action.value
            };
        case "INIT":
            return action.value;
        case "SET_QUESTIONS":
            return {
                ...state,
                "questions": action.questions
            };
        case "ADD_QUESTIONS":
            action.questions.forEach(item => {
                questions = [...questions, item]
            });
            return {
                ...state,
                "questions": questions
            };
        case "ADD_QUESTION":
            return {
                ...state,
                "questions": [...state.questions, action.question]
            };
        case "UPDATE_QUESTION_TEXT":
            questions.splice(action.index,1, {...state.questions[action.index], question: action.value, question_error: undefined});
            return {
                ...state,
                "questions": questions
            };
        case "UPDATE_QUESTION_ANSWER":
            questions.splice(action.index,1, {...state.questions[action.index], ["answer"+action.aindex]: action.value, ["answer"+action.aindex+"_error"]: undefined});
            return {
                ...state,
                "questions": questions
            };
        case "UPDATE_QUESTION_IMAGE":
            questions.splice(action.index,1, {...state.questions[action.index], smallImageUrl: action.smallImageUrl, bigImageUrl: action.bigImageUrl});
            return {
                ...state,
                "questions": questions
            };
        case "UPDATE_QUESTION_CORRECT_ANSWER":
            questions.splice(action.index,1, {...state.questions[action.index], correctAnswer: action.aindex});
            return {
                ...state,
                "questions": questions
            };
        case "MOVE_QUESTION_DOWN":
            var element = questions[action.index];
            questions.splice(action.index, 1);
            questions.splice(action.index+1, 0, element);
            return {
                ...state,
                "questions": questions
            };
        case "MOVE_QUESTION_UP":
            var element = questions[action.index];
            questions.splice(action.index, 1);
            questions.splice(action.index-1, 0, element);
            return {
                ...state,
                "questions": questions
            };
        case "DELETE_QUESTION":
            questions.splice(action.index,1);
            return {
                ...state,
                "questions": questions
            };
        case "SET_QUESTION_ERROR":
            questions.splice(action.index,1, {...state.questions[action.index], [action.field+"_error"]: action.value});
            return {
                ...state,
                "questions": questions
            };
        default:
            return state;
    }
};

export default quizzReducer;