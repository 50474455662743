import React, {Component, useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},

}));

export default function ProposeConfirmation(props) {


    useEffect(() => {
    });

    const classes = useStyles();


    return (
        <Container maxWidth="sm">
            <Paper elevation="3" className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    Thank you!
                </Typography>
                <p className="lead">Your question proposition has been submitted and will be reviewed soon.</p>
                <Button component={RouterLink} variant="contained" color="primary" to="/market/propose" style={{marginTop: 2 + "em"}}>
                    Propose another question
                </Button>
                <p>
                    <Link component={RouterLink} color="primary" to="/">
                        Back to homepage
                    </Link>
                </p>
            </Paper>
        </Container>
    )
}