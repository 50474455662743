import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Chip from "@material-ui/core/Chip";
import {getStatus} from "../../utils";


const useStyles = makeStyles((theme) => ({

    status_1: {
        backgroundColor: "#28a12c",
        color: "#FFFFFF"
    },
    status_0: {
        backgroundColor: "#616161",
        color: "#FFFFFF"
    },
    status_2: {
        backgroundColor: "#9c2322",
        color: "#FFFFFF"
    },
}));

export default function StatusChip(props) {

    const classes = useStyles();

    return (
        <Chip className={classes["status_"+props.status]} label={getStatus(props.status)} size="small"/>
    )
}