import React, {Component, useContext, useEffect, useState, useRef} from "react";
import {format, parseISO} from "date-fns"

import axios from "axios";

import {QUIZZ_DETAIL_URL, QUIZZ_URL} from "../../../constants";
import useAxiosInstance from "../../../api/axiosApi";
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ShareDialog from "./ShareDialog";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import Chip from '@material-ui/core/Chip';
import Link from "@material-ui/core/Link";
import {getStatus, hasAnyLicense, hasLicense} from "../../../utils";
import StatusChip from "../../common/StatusChip";
import ActivateLicenseDialog from "../../account/ActivateLicenseDialog";
import ImportQuizzDialog from "./ImportQuizzDialog";
import ErrorMsg from "../../common/ErrorMsg";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {AuthContext} from "../../contexts/AuthContext";
import {Link as RouterLink} from 'react-router-dom';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ConfigContext from "../../contexts/ConfigContext";
import ImportPublicQuizzDialog from "./ImportPublicQuizzDialog";
import TextField from "@material-ui/core/TextField";

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    link: {
        cursor: "pointer"
    }
}));


export default function Quizz(props) {
    const classes = useStyles();
    const axiosInstance = useAxiosInstance(props);
    const authContext = useContext(AuthContext);
    const {config} = useContext(ConfigContext);

    const [quizzList, setQuizzList] = useState([]);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [importPublicDialogOpen, setImportPublicDialogOpen] = useState(false);
    const [infoMessage, setInfoMessage] = useState({});
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created');
    const [selected, setSelected] = React.useState([]);
    const [searchKeyword, setSearchKeyword] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [shareCode, setShareCode] = useState("");
    const [makePublic, setMakePublic] = useState(false);
    const [filter, setFilter] = useState("");
    const [successOpen, setSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [licenseInfoOpen, setLicenseInfoOpen] = React.useState(false);
    const [activeQuizzCount, setActiveQuizzCount] = useState(0);
    const [isCreationActive, setIsCreationActive] = useState(false);
    const abortControllerRef = React.useRef(null);

    const closeImportDialog = (success) => {
        setImportDialogOpen(false);
    };

    const closeImportPublicDialog = (success) => {
        setImportPublicDialogOpen(false);
    };

    const closeShareDialog = (save, makePublic) => {
        if (save) {
            axiosInstance.post('/api/quizz/_set_public/' + selected[0], {public: makePublic}).then(response => {
                getQuizzList();
                setShareDialogOpen(false);
            })
                .catch(error => {
                    console.log(error);
                });
        } else setShareDialogOpen(false);
    };

    const getQuizzList = () => {
        let quizzData = [];
        if (abortControllerRef.current) abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
        let activeQuizzCount = 0;

        axiosInstance.get(QUIZZ_URL, {"params": {"search": searchKeyword}, "signal": abortControllerRef.current.signal}).then(res => {
            res.data.map((quizz) => {
                quizzData.push(
                    {
                        "id": quizz.id,
                        "created": quizz.created_date,
                        "code": quizz.code,
                        "share_code": quizz.share_code,
                        "title": quizz.title,
                        "description": quizz.description,
                        "nb_questions": quizz.questions ? quizz.questions.length : 0,
                        "status": quizz.status,
                        "duration": quizz.duration,
                        "level": quizz.level,
                        "public": quizz.public ? "Yes" : "No",
                        "raw": quizz,
                        "percentage": (quizz.number_students && quizz.num_responses) ? Math.round(quizz.num_responses / quizz.number_students * 100) + "%" : "---"
                    }
                );
                if (quizz.status === 0 || quizz.status === 1) {
                    activeQuizzCount++;
                }

            });
            setQuizzList(quizzData);
            setActiveQuizzCount(activeQuizzCount);
            setIsCreationActive(true);
        });

    };

    const startQuizz = () => {
        axiosInstance.post('/api/quizz/_start_now/' + selected).then(res => {
            props.history.push("/quizz/detail/" + selected);
        });
    };

    const stopQuizz = () => {
        axiosInstance.post('/api/quizz/_stop_now/' + selected).then(res => {
            setInfoMessage({title: "Quizz stopped", message: "This quizz has been stopped."});
            getQuizzList();
            setSelected([]);
        });

    };

    const duplicateQuizz = () => {
        axiosInstance.post('/api/quizz/_duplicate/' + selected[0]).then(res => {
            setSuccessMessage("The quizz has been successfully duplicated");
            setSuccessOpen(true);
            getQuizzList();
            setSelected([])
        });
    };

    const handleImportSuccess = () => {
        setImportDialogOpen(false);
        setImportPublicDialogOpen(false);
        setSuccessMessage("The quizz has been successfully imported");
        setSuccessOpen(true);
        getQuizzList();
    };

    const deleteQuizz = () => {
        let promises = [];
        selected.map((value, index) => {
            promises.push(axiosInstance.delete('/api/quizz/' + value));
        });
        axios.all(promises).then(responseArr => {
            getQuizzList();
            setSelected([]);
        });
    };

    const openShareQuizz = () => {
        let filteredQuizzList = quizzList.filter((value, index) => {
            return value.code === selected[0]
        });

        setShareCode(filteredQuizzList[0].share_code);
        setMakePublic(filteredQuizzList[0].raw.public);
        setShareDialogOpen(true);
    };

    const exportToExcel = () => {
        axiosInstance.get(config.FRONT_BACK_BASE_URL + "api/quizz/_export/" + selected.join(",")).then(response => {
            var bin = atob(response.data);
            var ab = s2ab(bin);
            const blob = new Blob([ab], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = "texquiz_export.xlsx";
            link.href = href;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });


    };


    const applyFilter = (event) => {
        setFilter(event.target.value);
    };

    useEffect(() => {
        getQuizzList();
    }, [searchKeyword]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = quizzList.map((n) => n.code);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleDetail = (code, status) => {
        if (status === 0) props.history.push("/edit_quizz/" + code);
        else props.history.push("/quizz/detail/" + code);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleActiveQuizzes = (action) => {
        console.log(authContext.state.user);
        if (activeQuizzCount < 5 || hasLicense(authContext.state.user, "P")) action();
        else setLicenseInfoOpen(true);
    };

    const handlePlansClick = () => {
        props.history.push("/plans");
        setLicenseInfoOpen(false);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    const newQuizz = () => {
        props.history.push("/create_quizz")
    };

    const getSelectedQuizzAttribute = (attribute) => {
        if (selected.length > 0) {
            let filteredQuizzList = quizzList.filter((value, index) => {
                return value.code === selected[0]
            });
            return filteredQuizzList[0][attribute];
        }
        return "";
    };

    return (
        <Container maxWidth="lg">
            <ShareDialog open={shareDialogOpen} title="Share this quizz" public={makePublic}
                         onClose={closeShareDialog}>
                To share this quizz with other users, please communicate the following code:
                <br/><br/>
                <b>{shareCode}</b>
            </ShareDialog>
            <Grid container justify="space-between" style={{marginTop: 2 + "em", marginBottom: 2 + "em"}}>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={12}>
                    <div align="right">
                        <Button disabled={!isCreationActive} variant="contained" color="primary"
                                onClick={() => handleActiveQuizzes(() => setImportPublicDialogOpen(true))}
                                style={{marginRight: 1 + "em"}}>
                            Import a public quizz
                        </Button>
                        <Button disabled={!isCreationActive} variant="contained" color="primary"
                                onClick={() => handleActiveQuizzes(() => setImportDialogOpen(true))}
                                style={{marginRight: 1 + "em"}}>
                            Import a private quizz
                        </Button>
                        <Button disabled={!isCreationActive} variant="contained" color="primary" onClick={() => handleActiveQuizzes(() => newQuizz())}>
                            Create a new quizz
                        </Button>

                    </div>
                </Grid>
            </Grid>
            <Grid container direction={"row"}>
                <Grid item xs={12} alignContent={"flex-end"}>
                    <TextField
                        label="Search keyword"
                        value={searchKeyword}
                        variant="outlined"
                        size={"small"}
                        style={{marginBottom: "5px", float: "right"}}
                        onChange={(event) => setSearchKeyword(event.target.value)}>
                    </TextField>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar selected={selected} numSelected={selected.length} filter={filter}
                                      onDuplicate={() => handleActiveQuizzes(duplicateQuizz)} onDelete={deleteQuizz} onStart={startQuizz}
                                      onStop={stopQuizz} onShare={openShareQuizz} onExport={exportToExcel}
                                      onFilter={applyFilter}/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={quizzList.length}
                        />
                        <TableBody>
                            {stableSort(quizzList, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.code);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.code)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.code}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                />
                                            </TableCell>
                                            <TableCell align="left"><Link className={classes.link}
                                                onClick={(event) => handleDetail(row.code, row.status)}>{row.title}</Link></TableCell>
                                            <TableCell align="left">{row.description}</TableCell>    
                                            <TableCell align="left"><StatusChip status={row.status}/></TableCell>
                                            <TableCell align="left">{row.nb_questions}</TableCell>
                                            <TableCell align="left">{row.duration} mn</TableCell>
                                            <TableCell align="left">{row.percentage}</TableCell>
                                            <TableCell align="left">{row.public}</TableCell>
                                            <TableCell
                                                align="left">{format(parseISO(row.created), "dd.MM.yyyy")}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={quizzList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Paper>
            <React.Fragment>
                <ImportQuizzDialog open={importDialogOpen} onClose={closeImportDialog}
                                    onSuccess={handleImportSuccess}/>
                < ImportPublicQuizzDialog open={importPublicDialogOpen} onClose={closeImportPublicDialog}
                                            onSuccess={handleImportSuccess}/>
            </React.Fragment>
            <Dialog
                open={Object.keys(infoMessage).length > 0}
                onClose={() => setInfoMessage({})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{infoMessage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {infoMessage.message}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={licenseInfoOpen}
                onClose={() => setLicenseInfoOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Activate your license</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>Get the Pro version to manage more than 5 active quizzes.</p>
                        <Button component={RouterLink} variant="contained" color="primary" onClick={handlePlansClick}>
                            Activate your Pro license
                        </Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Snackbar open={successOpen} autoHideDuration={5000} onClose={() => setSuccessOpen(false)}>
                <Alert onClose={() => setSuccessOpen(false)} severity="success">
                    {successMessage}
                </Alert>
            </Snackbar>
        </Container>
    );

}
