import React, {useState} from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
    drawer: {
        width: 250,
        padding: 1 + "em"
    },
    fullList: {
        width: 'auto',
    },
});

export function CreateQuizzDrawer(props) {
    const classes = useStyles();

    const toggleDrawer = () => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    };

    return (
        <SwipeableDrawer
            anchor="right"
            open={props.open}
            onOpen={() => void 0}
            onClose={() => props.onClose()}
        >
            <div className={classes.drawer} onKeyDown={toggleDrawer()}>
                <Grid container justify="space-between" style={{marginTop: 2 + "em"}}>
                    <Grid item sm={12}>
                        <Typography variant="h6" color="textSecondary">
                            Additional options
                        </Typography>
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 2 + "em"}}>
                        <Tooltip title="Shuffle the questions for the participant">
                            <FormControlLabel
                                control={<Switch checked={props.isRandomQuestions}
                                                 onChange={event => props.setIsRandomQuestions(event.target.checked)}/>}
                                label="Random questions"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12}>
                        <Tooltip title="Shuffle the answers for the participant">
                            <FormControlLabel
                                control={<Switch checked={props.isRandomAnswers}
                                                 onChange={event => props.setIsRandomAnswers(event.target.checked)}/>}
                                label="Random answers"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12}>
                        <Tooltip title="Do not ask for the firstname and lastname">
                            <FormControlLabel
                                control={<Switch checked={props.isAnonymous}
                                                 onChange={event => props.setIsAnonymous(event.target.checked)}/>}
                                label="Anonymous"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 1 + "em"}}>
                        <Tooltip title="Set the number of students to provide more accurate statistics">
                            <TextField
                                name="nbStudents"
                                label="Number of Student"
                                value={props.numberStudents}
                                type="number"
                                autoComplete=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    let input = event.target.value;
                                    if (!input || input>=0)
                                        props.setNumberOfStudents(event.target.value)
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 2 + "em"}}>
                        <b>At the end of the quizz</b>
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 1 + "em"}}>
                        <Tooltip title="Allow the participants to see the correct answers">
                            <FormControlLabel
                                control={<Switch checked={props.areAnswersAvailable}
                                                 onChange={event => props.setAreAnswersAvailable(event.target.checked)}/>}
                                label="Answers available"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 1 + "em"}}>
                        <Tooltip title="Participants can rate the difficulty of the quizz">
                            <FormControlLabel
                                control={<Switch checked={props.isRatingEnabled}
                                                 onChange={event => props.setIsRatingEnabled(event.target.checked)}/>}
                                label="Difficulty rating allowed"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 1 + "em"}}>
                        <Tooltip title="Participants can provide a comment in the survey">
                            <FormControlLabel
                                control={<Switch checked={props.isCommentAllowed}
                                                 onChange={event => props.setIsCommentAllowed(event.target.checked)}/>}
                                label="Comment allowed"
                            />
                        </Tooltip>
                    </Grid>
                </Grid>

            </div>

        </SwipeableDrawer>
    )
}