import React, {Component, Fragment, useContext, useEffect, useState} from "react";
import useAxiosInstance from "../../../api/axiosApi";
import {QUIZZ_REPONSES_URL, QUIZZ_URL} from "../../../constants";
import Grid from "@material-ui/core/Grid";
import ConfigContext from "../../contexts/ConfigContext";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import StatusChip from "../../common/StatusChip";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import QuizzAnswersReport from "./QuizzAnswersReport";
import QuizzDetailShare from "./QuizzDetailShare";
import Chart from "react-google-charts";
import CircularProgressWithLabel from "../../common/CircularProgressWithLabel"
import QuizzNameReport from "./QuizzNameReport";
import QuizzRankReport from "./QuizzRankReport";
import QuizzCommentReport from "./QuizzCommentReport";
import Box from "@material-ui/core/Box";
import QuizzDifficultyReport from "./QuizzDifficultyReport";
import ProgressBar from "../../common/ProgressBar";
import Alert from "@material-ui/lab/Alert";
import {AuthContext} from "../../contexts/AuthContext";
import {Link as RouterLink} from 'react-router-dom';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme) => ({
    quizzLink: {
        overflowWrap: "break-word"
    }

}));

export default function QuizzDetail(props) {

    const axiosInstance = useAxiosInstance(props);
    const [quizz, setQuizz] = useState({});
    const [responses, setResponses] = useState([]);
    const [meanScore, setMeanScore] = useState(0);
    const [standardDeviation, setStandardDeviation] = useState(0);
    const [numResponses, setNumResponses] = useState(0);
    const [questionsReport, setQuestionsReport] = useState([]);
    const [namesReport, setNamesReport] = useState([]);
    const [ranksReport, setRanksReport] = useState([]);
    const [difficultyReport, setDifficultyReport] = useState({});
    const [tooManyStudent, setTooManyStudent] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [openShareDialog, setOpenShareDialog] = React.useState(false);
    const [infoMessage, setInfoMessage] = useState({});

    const authContext = useContext(AuthContext);
    const {config} = useContext(ConfigContext);

    const classes = useStyles();

    useEffect(() => {
        var timer;
        axiosInstance.get(QUIZZ_URL + "/" + props.match.params.code).then(res => {
            setQuizz(res.data);
            refreshResponses(res.data);
            timer = setInterval(() => {
                refreshResponses(res.data);
            }, 5000);

        });

        return () => {
            clearInterval(timer);
        };
    }, []);

    const stopQuizz = (code) => {
        axiosInstance.post('/api/quizz/_stop_now/' + code).then(res => {
            setInfoMessage({title: "Quizz stopped", message: "This quizz has been stopped."});
            axiosInstance.get(QUIZZ_URL + "/" + props.match.params.code).then(res => {
                setQuizz(res.data);
            });
        });

    };

    const refreshResponses = (quizz) => {
        axiosInstance.get(QUIZZ_URL + "/" + props.match.params.code + "/responses").then(res => {
            let responsesData = res.data.raw_data;
            let aggregated_data = res.data;

            if (Object.keys(aggregated_data).length > 0) {

                setQuestionsReport(aggregated_data["questions_report"]);
                setResponses(responsesData);
                setMeanScore(aggregated_data["mean_score"]);
                setStandardDeviation(aggregated_data["standard_deviation"]);
                setNumResponses(res.data.num_responses);

                if (aggregated_data["participants"]) {
                    //names report
                    aggregated_data["participants"].sort((first, second) => {
                        return (first.last_name || "").localeCompare(second.last_name || "")
                    });
                    setNamesReport([...aggregated_data["participants"]]);
                    //rank report
                    aggregated_data["participants"].sort((first, second) => {
                        return first.rank - second.rank
                    });
                    setRanksReport([...aggregated_data["participants"]]);
                }
                setDifficultyReport(aggregated_data["difficulty"]);
            }
            setIsLoading(false);
        });
    };


    const handleShareDialogClose = () => {
        setOpenShareDialog(false);
    };

    if (isLoading) {
        return (
            <Container maxWidth="lg" component="main" style={{marginTop: 2 + "em"}}>
                <Box hidden={props.hidden}>Loading...</Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" component="main" style={{marginTop: 2 + "em"}}>
            <Link component={RouterLink} variant="button" to="/quizz">&lt; Back</Link><br/>

            <Grid container justify="space-between" style={{marginTop: 2 + "em", marginBottom: 2 + "em"}}>
                <Grid item xs={6}>
                    <Typography variant="h2" component="h2">
                        {quizz.title}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {quizz.description}
                    </Typography>
                    Status: <StatusChip status={quizz.status}/>
                    <br/><br/>
                    <QuizzDetailShare open={openShareDialog} onClose={handleShareDialogClose} quizz={quizz}/>

                    {
                        quizz.status === 1 ?

                            <Button variant="contained" color="primary" style={{marginTop: 1 + "em"}}
                                    onClick={() => stopQuizz(props.match.params.code)}>
                                Close the quizz
                            </Button>
                            : null
                    }

                </Grid>
                <Grid item xs={6} style={{textAlign: "right"}}>
                    <img src={config.FRONT_BACK_BASE_URL + "image/quizz/qrcode/" + quizz.code + "/5"}/><br/>
                    <Typography gutterBottom variant="h5" component="h2">
                        Code: {quizz.code}<br/>
                        <Link className={classes.quizzLink}
                              href={config.FRONT_BASE_URL + "q/" + quizz.code}>{config.FRONT_BASE_URL + "q/" + quizz.code}</Link>
                        <Link component="button" onClick={() => setOpenShareDialog(true)}>Share code (presentation
                            mode)</Link><br/>

                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{padding: 2 + "em", marginTop: 2 + "em"}}>
                        <Typography gutterBottom variant="h5" component="h2">
                            Global results
                        </Typography>
                        <Grid container justify="space-between" style={{marginTop: 2 + "em", marginBottom: 2 + "em"}}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} md={4} style={{paddingTop: 2 + "px"}}>
                                        {
                                            quizz.number_students > 0 ?
                                                <div>Participation (in
                                                    progress): {numResponses} / {quizz.number_students}</div>
                                                : <div>Participation (in progress): {numResponses}</div>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        {
                                            quizz.number_students > 0 ?
                                                <ProgressBar bgColor={"#f39200"} fillColor={"#28a12c"}
                                                             completed={Math.round(numResponses / quizz.number_students * 100)}
                                                             width={100}/>
                                                : null
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{paddingTop: 2 + "px"}}>
                                        {
                                            quizz.number_students > 0 ?
                                                <div>Participation
                                                    (finished): {responses.length} / {quizz.number_students}</div>
                                                : <div>Participation (finished): {responses.length}</div>
                                        }

                                        {meanScore ?
                                            <div>Mean score: {meanScore}%</div>
                                            : undefined
                                        }
                                        {standardDeviation ?
                                            <div>Standard deviation: {standardDeviation}%</div>
                                            : undefined
                                        }

                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        {
                                            quizz.number_students > 0 ?
                                                <ProgressBar bgColor={"#f39200"} fillColor={"#28a12c"}
                                                             completed={Math.round(responses.length / quizz.number_students * 100)}
                                                             width={100}/>
                                                : null
                                        }
                                    </Grid>


                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>

                    {
                        (tooManyStudent) ? (
                            <Paper elevation={3} style={{padding: 2 + "em", marginTop: 2 + "em"}}>
                                <Alert severity="error">
                                    The number of responses exceeds the limit of your plan.
                                    <p>
                                        Only the first 15 results will be displayed. To show all results, please buy a
                                        Pro plan:
                                    </p>
                                    <Button component={RouterLink} variant="contained" color="primary"
                                            href="/billing/plan">
                                        Get the Pro version
                                    </Button>
                                </Alert>

                            </Paper>
                        ) : null
                    }

                    <Paper elevation={3} style={{padding: 2 + "em", marginTop: 2 + "em"}}>
                        <Typography gutterBottom variant="h5" component="h2">
                            Results by question
                        </Typography>
                        <QuizzAnswersReport data={questionsReport} quizz={quizz}/>

                    </Paper>

                    {
                        !quizz.anonymous ?
                            <Paper elevation={3} style={{padding: 2 + "em", marginTop: 2 + "em"}}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Results by name
                                </Typography>
                                <QuizzNameReport data={namesReport}/>
                            </Paper>
                            : null
                    }

                    {
                        !quizz.anonymous ?
                            <Paper elevation={3} style={{padding: 2 + "em", marginTop: 2 + "em"}}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Results by rank
                                </Typography>
                                <QuizzRankReport data={ranksReport}/>
                            </Paper>
                            : null
                    }

                    {
                        quizz.rating_enabled ?
                            <Paper elevation={3} style={{padding: 2 + "em", marginTop: 2 + "em"}}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Difficulty
                                </Typography>
                                <QuizzDifficultyReport data={difficultyReport} total={responses.length}/>
                            </Paper>
                            : null
                    }

                    {
                        quizz.comment_allowed ?
                            <Paper elevation={3} style={{padding: 2 + "em", marginTop: 2 + "em"}}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Comments
                                </Typography>
                                <QuizzCommentReport anonymous={quizz.anonymous} data={responses}/>
                            </Paper>
                            : null
                    }


                </Grid>
            </Grid>
            <Dialog
                open={Object.keys(infoMessage).length > 0}
                onClose={() => setInfoMessage({})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{infoMessage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {infoMessage.message}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Container>
    )
}