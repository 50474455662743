import React, {Component, useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useAxiosInstance from "../../../api/axiosApi";
import {useHistory} from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import {InlineTex} from "react-tex";
import {convertCarriageReturns} from "../../../utils";
import Grid from "@material-ui/core/Grid";
import PreviewImage from "../../common/PreviewImage";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Radio from "@material-ui/core/Radio";
import ProgressBar from "../../common/ProgressBar";
import {labels} from "../../../data/quizz.js"
import QuestionsButtons from "../../common/QuestionsButtons";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    cardHeader: {
        backgroundColor: theme.palette.grey[200],
        fontSize: '24px',
    },
    btn: {
        marginTop: 2+"em",
        marginBottom: 2+"em"
    }
}));

export default function ReviewQuizz(props) {

    let history = useHistory();

    const [quizz, setQuizz] = useState();
    const [questions, setQuestions] = useState([]);
    const [responses, setReponses] = useState({});
    const [step, setStep] = useState(0);
    const [answers, setAnswers] = useState({});
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const axiosInstance = useAxiosInstance(props);

    useEffect(() => {
        setQuizz(props.location.state);

        let session_id = localStorage.getItem("quizz_" + props.match.params.code);
        axiosInstance.get('/api/quizz/_review/'+props.match.params.code+"?session_id="+session_id, {headers:{"Authorization":null}}).then(response => {
            if (response.status === 200 && response.data.quizz.questions) {
                setQuizz(response.data.quizz);
                let questionsArray = response.data.quizz.questions;
                setQuestions(questionsArray);
                setIsLoading(false);

                let responses = {};
                response.data.responses.questions.forEach(response => {
                    responses[response.question] = response.answer;
                });
                setReponses(responses);

                let correctAnswers = 0;
                questionsArray.forEach(question => {
                   if (question.correct_answer === responses[question.id]) correctAnswers++;
                });
                setCorrectAnswers(correctAnswers);
            }
        });
    }, []);

    const classes = useStyles();


    const previousQuestion = (event, index) => {
        setStep(step - 1);

    };

    const nextQuestion = (event, index) => {
        setStep(step + 1);

    };

    const toQuestion = (index) => {
        setStep(index);
    };

    const resolveRadioClass = (step, key) => {
        if (questions[step].correct_answer === key) return {color: "#28a12c"};
        else if (responses[questions[step].id] === key) return {color: "#E95931"}
        else return {};
    }

    const currentAnswers = (step) => {
        let content = [];
        for (let i = 1; i <= 4; i++) {
            if (questions[step]["answer"+i])
                content.push(
                    <li key={i} style={resolveRadioClass(step, i)}>
                        {labels[i-1]}.&nbsp;<Radio
                            style={resolveRadioClass(step, i)}
                            checked={questions[step].correct_answer === i}
                            disabled
                            value={i}
                            name="answer"
                        />
                        <InlineTex texContent={convertCarriageReturns(questions[step]["answer"+i])} texSeperator="${1}"/>
                    </li>
                )
        }
        return (
            <ul>
                {content}
            </ul>
        );
    };


    const currentQuestion = (step) => {
        if (questions) {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Typography gutterBottom variant="h6" component="h2">
                            Question {step+1}
                        </Typography>
                        <InlineTex texContent={convertCarriageReturns(questions[step].text)} texSeperator="${1}"/>
                    </Grid>

                    <Grid item sm={9}>
                        {currentAnswers(step)}
                    </Grid>
                    <Grid item sm={3}>
                        <PreviewImage smallImageUrl={questions[step].smallImageUrl} bigImageUrl={questions[step].bigImageUrl} />
                    </Grid>
                </Grid>
            )
        } else return (<div/>)
    };


    if (isLoading) {
        return  (
            <Container maxWidth="lg" component="main" style={{marginTop: 2+"em"}}>
                <Box hidden={props.hidden}>Loading...</Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" component="main" style={{marginTop: 2+"em"}}>
            <p>
                <Link component={RouterLink} variant="button" to={"/end_quizz/"+props.match.params.code}>&lt; Back to survey</Link>
            </p>
            <Typography variant="h5" gutterBottom color="primary">
                Your result
            </Typography>
            <Grid container justify="space-between" >

                <Grid item sm={8}>
                    <Card style={{marginTop: 2 + "em"}}>
                        <CardHeader disableTypography={true} className={classes.cardHeader} title={quizz.description}/>
                        <CardContent style={{paddingBottom: 2+"em"}}>

                            {currentQuestion(step)}

                            {
                                questions && step < questions.length - 1 ? (
                                    <Button className={classes.btn} variant="contained" color="primary" style={{float: "right"}} onClick={nextQuestion}>Next</Button>
                                ) : ""
                            }
                            {
                                step > 0 ? (
                                    <Button className={classes.btn} variant="contained" color="secondary" onClick={previousQuestion}>Previous</Button>
                                ) : ""
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={3}>
                    <div style={{margin: "auto", marginTop: 2+"em"}}>
                        <Typography variant="h6" gutterBottom color="primary">
                            Percentage of correct answers
                        </Typography>
                        <ProgressBar bgColor={"#f39200"} fillColor={"#28a12c"} completed={Math.round(correctAnswers / questions.length * 100)} width={100}/>

                        <div style={{clear:"both", marginTop: 2+"em"}}>
                            <QuestionsButtons questions={questions} answers={answers} toQuestion={toQuestion}/>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );



}
