import React, {Component, useEffect, useState} from "react";
import useAxiosInstance from "../../../api/axiosApi";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Profile from "../Profile";

export default function Signup (props) {


    const useStyles = makeStyles((theme) => ({
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
                width: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            }},
        formElem: {
            marginTop: '1.5em'
        }
    }));

    const classes = useStyles();

    return (
        <Container maxWidth="md">
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    Create your account
                </Typography>
                <Profile hidden={false} editMode={false}/>
            </Paper>
        </Container>
    );
}

