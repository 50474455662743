import React, {Component, Fragment, useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import SwipeableTextMobileStepper from "../../common/SwipeableTextMobileStepper";


const useStyles = makeStyles({
    table: {

    },

    quoteCard: {
        background: "#FFF",
        color: "#222",
        padding: "20px",
        paddingLeft: "80px",
        boxSizing: "border-box",
        boxShadow: "0 2px 4px rgba(34, 34, 34, 0.4)",
        position: "relative",
        overflow: "hidden",
        minHeight: "120px",

        '&::before': {
            fontFamily: "Georgia, serif",
            content: "'“'",
            position: "absolute",
            top: "10px",
            left: "10px",
            fontSize: "5em",
            color: "rgba(40, 161, 44, 0.6)",
            fontWeight: "normal",
        }
    },

    quoteCardOdd: {
        background: "#e3e3e3",
    },

    cite: {
        fontStyle: "italic",
        fontSize: "16px",
        marginTop: "10px",
        display: "block",
        fontWeight: "200",
        opacity: "0.8"
    },

    text: {
        fontSize: "18px",
        lineHeight: "1.5",
        margin: 0,
        maxWidth: "100%"
    }

});



export default function QuizzCommentReport(props) {
    const classes = useStyles();

    const [data, setData] = useState([]);

    useEffect(() => {
        let data = [];
        props.data.forEach(response => {
            if (response.comment) {
                data.push({
                    label: (!props.anonymous) ? response.first_name + " " + response.last_name : "Anonymous",
                    text: response.comment
                })
            }
        });
        setData(data);
    }, [props]);

    return (
        data.length>0 ?
            <React.Fragment>
                {data.map((step, index) => (
                    <div key={"comment"+index} className={classes.text}>

                        {step.text ? (
                            <blockquote className={`${classes.quoteCard} ${(index % 2 === 1 ? classes.quoteCardOdd : '')}`}>
                                <p className={classes.text}>
                                    <span dangerouslySetInnerHTML={{__html: step.text.replaceAll("\n", "<br/>")}}></span>
                                </p>

                                <cite className={classes.cite}>
                                    {step.label}
                                </cite>
                            </blockquote>
                        ) : null
                        }
                    </div>
                ))}
            </React.Fragment> : null
    );
}