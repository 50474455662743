const questionsReducer = (state, action) => {
    let newQuestions = [...state];
    switch (action.type) {
        case "SET_QUESTIONS":
            return action.questions;
        case "ADD_QUESTIONS":
            action.questions.forEach(item => {
                newQuestions = [...newQuestions, item];
            });
            return newQuestions;
        case "ADD_QUESTION":
            return [...state, action.question];
        case "UPDATE_QUESTION_TEXT":
            newQuestions.splice(action.index,1, {...state[action.index], question: action.value, question_error: undefined});
            return newQuestions;
        case "UPDATE_EXPLANATION_TEXT":
            newQuestions.splice(action.index,1, {...state[action.index], explanation: action.value, explanation_error: undefined});
            return newQuestions;
        case "UPDATE_QUESTION_ANSWER":
            newQuestions.splice(action.index,1, {...state[action.index], ["answer"+action.aindex]: action.value, ["answer"+action.aindex+"_error"]: undefined});
            return newQuestions;
        case "UPDATE_QUESTION_IMAGE":
            newQuestions.splice(action.index,1, {...state[action.index], smallImageUrl: action.smallImageUrl, bigImageUrl: action.bigImageUrl});
            return newQuestions;
        case "UPDATE_EXPLANATION_IMAGE":
            newQuestions.splice(action.index,1, {...state[action.index], explanationSmallImageUrl: action.explanationSmallImageUrl, explanationBigImageUrl: action.explanationBigImageUrl});
            return newQuestions;
        case "UPDATE_QUESTION_CORRECT_ANSWER":
            newQuestions.splice(action.index,1, {...state[action.index], correctAnswer: action.aindex});
            return newQuestions;
        case "MOVE_QUESTION_DOWN":
            var element = newQuestions[action.index];
            newQuestions.splice(action.index, 1);
            newQuestions.splice(action.index+1, 0, element);
            return newQuestions;
        case "MOVE_QUESTION_UP":
            var element = newQuestions[action.index];
            newQuestions.splice(action.index, 1);
            newQuestions.splice(action.index-1, 0, element);
            return newQuestions;
        case "DELETE_QUESTION":
            newQuestions.splice(action.index,1);
            return newQuestions;
        case "SET_QUESTION_ERROR":
            newQuestions.splice(action.index,1, {...state[action.index], [action.field+"_error"]: action.value});
            return newQuestions;
        default:
            return state;
    }
};

export default questionsReducer;