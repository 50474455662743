import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import {IMPORT_QUIZZ_URL} from "../../../constants";
import useAxiosInstance from "../../../api/axiosApi";
import { useHistory } from "react-router-dom";

export default function ImportQuizzDialog (props) {

    const [code, setCode] = useState("");
    const [errors, setErrors] = useState({});

    const axiosInstance = useAxiosInstance(props);
    let history = useHistory();

    useEffect( () => {

    }, []);

    const importQuizz = () => {
        axiosInstance.post(IMPORT_QUIZZ_URL + "/" + code).then(res => {
            props.onSuccess();
        }).catch(err => {
            setErrors({"code": "This license code is not valid."})
        });
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={() => props.onClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Import a quizz</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please enter here a quizz code.
                    </DialogContentText>
                    <Grid container justify="space-between" style={{marginTop: 2 + "em"}}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Code"
                                value={code}
                                fullWidth
                                autoComplete="on"
                                onChange={event => setCode(event.target.value)}
                                error={ errors.code || errors.code ? true : false}
                                helperText={errors.code || errors.code}
                            />

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={importQuizz} color="primary">
                        Import
                    </Button>
                    <Button onClick={() => props.onClose(false)} color="secondary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}