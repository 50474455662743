import React, {Component, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useAxiosInstance from "../../../api/axiosApi";
import {useHistory} from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ProgressBar from "../../common/ProgressBar";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    toggleButtonGroup: {
        '& .MuiToggleButton-root': {
            border: "0px",
            padding: "5px",

            '&.Mui-selected': {
                backgroundColor: "transparent",
                color: "#ffb900"
            }
        },
    },
}));


const customIcons = [
    {
        icon: <SentimentVeryDissatisfiedIcon style={{ fontSize: 40 }} />,
        label: 'Very difficult',
    },
    {
        icon: <SentimentDissatisfiedIcon style={{ fontSize: 40 }} />,
        label: 'Difficult',
    },
    {
        icon: <SentimentSatisfiedIcon style={{ fontSize: 40 }} />,
        label: 'Neutral',
    },
    {
        icon: <SentimentSatisfiedAltIcon style={{ fontSize: 40 }} />,
        label: 'Easy',
    },
    {
        icon: <SentimentVerySatisfiedIcon style={{ fontSize: 40 }} />,
        label: 'Very easy',
    }
];

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};


export default function EndQuizz(props) {

    let history = useHistory();

    const [quizz, setQuizz] = useState({});
    const [comment, setComment] = useState("");
    const [rating, setRating] = useState(0);
    const [surveySubmitted, setSurveySubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [responses, setReponses] = useState({});

    const axiosInstance = useAxiosInstance(props);


    const classes = useStyles();

    useEffect(() => {

        let session_id = localStorage.getItem("quizz_" + props.match.params.code);
        axiosInstance.get('/api/quizz/_review/'+props.match.params.code+"?session_id="+session_id, {headers:{"Authorization":null}}).then(response => {
            if (response.status === 200 && response.data.quizz.questions) {
                setQuizz(response.data.quizz);
                let questionsArray = response.data.quizz.questions;
                setQuestions(questionsArray);

                let responses = {};
                response.data.responses.questions.forEach(response => {
                    responses[response.question] = response.answer;
                });
                setReponses(responses);

                let correctAnswers = 0;
                questionsArray.forEach(question => {
                   if (question.correct_answer === responses[question.id]) correctAnswers++;
                });
                setCorrectAnswers(correctAnswers);

                setComment(response.data.responses.comment);
                setRating(response.data.responses.rating);
                setIsLoading(false);
            }
        });
    }, []);

    const showResults = () => {
        history.push("/review_quizz/"+quizz.code);
    };

    const submitSurvey = () => {
        let session_id = localStorage.getItem("quizz_" + props.match.params.code);
        try {

            let data = {
                "comment": comment,
                "rating": rating
            };
            axiosInstance.post('/api/quizz/response/_survey/' + props.match.params.code + "/" + session_id, data).then(response => {
                if (response.status === 200) {
                    setSurveySubmitted(true);
                }
            });
            //redirect to quizz
        } catch (error) {

        }
    };

    if (isLoading) {
        return  (
            <Container maxWidth="lg" component="main" style={{marginTop: 2+"em"}}>
                <Box hidden={props.hidden}>Loading...</Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    Thank you!
                </Typography>
                <p>Well done! You have completed this quizz and your answers have been submitted.</p>
                <Typography variant="h6" gutterBottom color="primary">
                    Your score
                </Typography>
                <ProgressBar bgColor={"#f39200"} fillColor={"#28a12c"} completed={Math.round(correctAnswers / questions.length * 100)} width={100}/>

                {
                    (quizz.answers_available) ? (
                            <React.Fragment>
                                <Box style={{textAlign:"center"}} component="fieldset" borderColor="transparent">
                                    <Button variant="contained" color="primary" onClick={showResults}>
                                        Display correct answers
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )
                        : null
                }

                {

                    (quizz.rating_enabled) ? (
                        <React.Fragment>
                            <p className="lead">Please take a few seconds to give your feedback about this quizz</p>
                            <hr className="my-4"/>

                            {
                                (!surveySubmitted) ? (
                                    <Box style={{textAlign:"center"}} component="fieldset" borderColor="transparent">
                                        <Typography component="legend">How easy was this quizz?</Typography>
                                        <ToggleButtonGroup
                                            className={classes.toggleButtonGroup}
                                            value={rating}
                                            exclusive
                                            onChange={(event, value) => setRating(value)}
                                            aria-label="text alignment"
                                        >
                                            {
                                                customIcons.map((item, index) => (
                                                    <ToggleButton disableRipple={true} value={index+1} aria-label={item.label}>
                                                        {item.icon}
                                                    </ToggleButton>
                                                ))
                                            }
                                        </ToggleButtonGroup>

                                        {

                                            (quizz.comment_allowed) ? (
                                                <React.Fragment>
                                                    <br/> <br/>
                                                    <Typography component = "legend" > Your comment</Typography>

                                                    <TextField
                                                        label="Comment"
                                                        value={comment}
                                                        fullWidth
                                                        variant="outlined"
                                                        multiline
                                                        rows={4}
                                                        onChange={event => setComment(event.target.value)}
                                                    />
                                                </React.Fragment>
                                            ) : null
                                        }


                                        <Button variant="contained" color="primary" onClick={submitSurvey} style={{marginTop: 2 + "em"}}>
                                            Submit
                                        </Button>


                                    </Box>
                                ) : (
                                    <Box style={{textAlign:"center"}} component="fieldset" borderColor="transparent">
                                        <p>Your feedback has been submitted. Thank you!</p>
                                        <Button component={RouterLink} variant="contained" color="primary" to="/" style={{marginTop: 2 + "em"}}>
                                            Back to home
                                        </Button>
                                    </Box>
                                )
                            }
                        </React.Fragment>
                    ) : null
                }
            </Paper>
        </Container>

    );


}
