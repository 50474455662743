import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ShareIcon from "@material-ui/icons/Share";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ConfirmDialog from "../../common/ConfirmDialog";
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import {lighten, makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";


const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));


export default function EnhancedTableToolbar (props) {
    const classes = useToolbarStyles();
    const {numSelected} = props;

    const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
    const [dialogStopOpen, setDialogStopOpen] = useState(false);

    const deleteItem = (confirm) => {
        if (confirm) {
            props.onDelete();
        }
        setDialogDeleteOpen(false);
    };


    const stopItem = (confirm) => {
        if (confirm) {
            props.onStop();
        }
        setDialogStopOpen(false);
    };

    const openShareDialog = () => {
        if (numSelected===1) {
            props.onShare();
        } else if (numSelected > 1) {
            //alert("Only one quizz can be selected")
        }
    };

    return (
        <Toolbar
            className={classes.root}
        >

            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Dashboard of your quizzes
            </Typography>



            <React.Fragment>
                <Tooltip disabled={(numSelected!==1)} title="Duplicate" onClick={props.onDuplicate}>
                    <IconButton aria-label="duplicate">
                        <FileCopyIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip disabled={(numSelected!==1)} title="Share this quizz" onClick={openShareDialog}>
                    <IconButton aria-label="share">
                        <ShareIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip disabled={(numSelected===0)} title="Export to Excel" onClick={props.onExport}>
                    <IconButton aria-label="export">
                        <GetAppIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip disabled={(numSelected!==1)} title="Start the quizz now" onClick={props.onStart}>
                    <IconButton aria-label="start">
                        <PlayArrowIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip disabled={(numSelected!==1)} title="Stop the quizz now" onClick={() => setDialogStopOpen(true)}>
                    <IconButton aria-label="Stop">
                        <StopIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip disabled={(numSelected===0)} title="Delete this quizz" onClick={() => setDialogDeleteOpen(true)}>
                    <IconButton aria-label="delete">
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
                <ConfirmDialog open={dialogDeleteOpen} title="Are you sure?" onClose={deleteItem}>
                    By clicking yes, the selected quizzes will be deleted without any possibility to recover them.
                </ConfirmDialog>
                <ConfirmDialog open={dialogStopOpen} title="Are you sure?" onClose={stopItem}>
                    By clicking yes, the quizz will be stopped. The participants that already entered the quizz can still submit their results.
                </ConfirmDialog>
            </React.Fragment>

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
