import axios from 'axios'
import {AuthContext} from "../components/contexts/AuthContext";
import {useContext, useState} from "react";

export default function useAxiosInstance(props) {

    const auth = useContext(AuthContext);
    const { history } = props;

    const redirectToLogin = () => {
        //redirect to login
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        auth.dispatch({
            type: "LOGOUT"
        });
        if (history) history.push('/login');
    };

    const axiosInstance = axios.create({
        baseURL: window.location.protocol+"//"+window.location.hostname+":8000/",
        timeout: 20000,
        headers: {
            'Authorization': "JWT " + localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
            'accept': 'application/json'
        }
    });

    axiosInstance.interceptors.request.use(req => {
        if (localStorage.getItem('access_token')) {
            req.headers["Authorization"] = "JWT " + localStorage.getItem('access_token');
        } else {
            delete req.headers["Authorization"]
        }
        return req;
    });

    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            const originalRequest = error.config;

            const refresh_token = localStorage.getItem('refresh_token');
            if (error.response && error.response.status === 401 && (error.response.statusText === "Unauthorized" && !originalRequest.url.includes("jwt/refresh")) && refresh_token && !originalRequest.url.includes("jwt/create")) {
                return axiosInstance
                    .post('/auth/jwt/refresh/', {refresh: refresh_token})
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access);
                        if (response.data.refresh) localStorage.setItem('refresh_token', response.data.refresh);

                        axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
                        originalRequest.headers['Authorization'] = "JWT " + response.data.access;

                        return axiosInstance(originalRequest);
                    })
                    .catch(err => {
                        redirectToLogin();
                    });
            } else {
                if (error.response && error.response.status === 401 && error.response.statusText === "Unauthorized") {
                    redirectToLogin();
                }
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
}