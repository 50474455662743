import React, {Component, Fragment, useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {

    },
});


export default function QuizzRankReport(props) {
    const classes = useStyles();

    return (

        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Last name</TableCell>
                    <TableCell>First name</TableCell>
                    <TableCell align="right">Rank</TableCell>
                    <TableCell align="right">%</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.map((row) => (
                    <TableRow key={row.last_name}>
                        <TableCell component="th" scope="row">
                            {row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {row.first_name}
                        </TableCell>
                        <TableCell align="right">{row.rank}</TableCell>
                        <TableCell align="right">{row.percentage}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>



    );
}