import React, {Component, useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},

}));

export default function ErrorMsg(props) {


    useEffect(() => {
    });

    const classes = useStyles();


    return (
        <Container maxWidth="sm">
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    {props.title}
                </Typography>
                <p className="lead">{props.children}</p>
                <Button component={RouterLink} variant="contained" color="primary" to="/" style={{marginTop: 2 + "em"}}>
                    Back to home
                </Button>

            </Paper>
        </Container>
    )
}