import React, {Component, Fragment, useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FixedSizeList } from 'react-window';
import * as PropTypes from "prop-types";
import Chart from "react-google-charts";

const useStyles = makeStyles({
    table: {

    },
});


const difficulty_labels = {
    5:"Very easy",
    4:"Easy",
    3:"Neutral",
    2:"Difficult",
    1:"Very difficult"
};

const difficulty_colors = {
    5:"#3a833c",
    4:"#51b653",
    3:"#acb86e",
    2:"#be6b58",
    1:"#ac4c46"
};

export default function QuizzDifficultyReport(props) {
    const classes = useStyles();

    function renderRow(props_comp) {
        const { index, style } = props_comp;

        return (
            <ListItem button style={style} key={index}>
                {props.data[index].comment}
            </ListItem>
        );
    }

    renderRow.propTypes = {
        index: PropTypes.number.isRequired,
        style: PropTypes.object.isRequired,
    };

    const computeData = () => {
        let data = [
            [
                'Element',
                'Density',
                { role: 'style' },
                {
                    sourceColumn: 0,
                    role: 'annotation',
                    type: 'string',
                    calc: 'stringify',
                },
            ]
        ];
        for (let i=1; i<=5; i++) {
            let value = props.data[i] ? props.data[i] : 0;
            let percentage = Math.round(value / props.total*100);
            data.push([difficulty_labels[i], percentage, difficulty_colors[i], percentage+"%"]);
        }
        if (props.data[null]) {
            let value = props.data[null];
            let percentage = Math.round(value / props.total*100);
            data.push(["No answer", percentage, "#7f7d7d", percentage+"%"]);
        }
        return data;
    };

    return (

        <Chart
            width={'100%'}
            height={'300px'}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={computeData()}
            options={{
                // Material design options
                chart: {
                    title: 'Correct answers distribution',

                },
                animation: {
                    easing: "linear",
                    duration: 1500,
                    startup: true
                },
                legend: { position: 'none' },
                enableInteractivity: false,
                bar: { groupWidth: '80%' },
                vAxes: {
                    // Adds titles to each axis.
                    0: {title: 'Students (%)'}
                },
                hAxes: {
                    0: {title: "Difficulty"}
                }


            }}

        />
    );
}