import React, {Component, useContext, useEffect, useState} from "react";
import useAxiosInstance from "../../../api/axiosApi";
import { makeStyles } from '@material-ui/core/styles';
import Alert from "@material-ui/lab/Alert";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

   const useStyles = makeStyles((theme) => ({
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            }},

    }));

export default function Activate(props) {
    const axiosInstance = useAxiosInstance(props);

    const [invalidToken, setInvalidToken] = useState(false);

    useEffect(() => {
        const {uid, token} = props.match.params;
        let messageString = "";
        activate(uid, token);
    });

    const activate = (uid, token) => {
        try {
            const response = axiosInstance.post('/auth/users/activation/', {
                uid: uid,
                token: token,
            });
            props.history.push("/login/activate");
        } catch (error) {
            setInvalidToken(true);
        }
    };

    const classes = useStyles();
    if (invalidToken) {
        var invalidTokenMessage =
            <Alert elevation={6} variant="filled" severity="error">
                Impossible to activate your account. Your link is maybe not anymore valid. Please contact the support.
            </Alert>

    }
    return (
        <Container maxWidth="sm">
            <Paper className={classes.paper}>
                {invalidTokenMessage}

            </Paper>
        </Container>
    )
}