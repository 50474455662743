import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useAxiosInstance from "../../api/axiosApi";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    formElem: {
        marginTop: '1.5em'
    }
}));

export default function Password (props) {

    let history = useHistory();

    const axiosInstance = useAxiosInstance(props);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRe, setNewPasswordRe] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [errors, setErrors] = useState({});


    const handleSubmit = async (event) => {
        event.preventDefault();
        let submitData = {
            new_password: newPassword,
            re_new_password: newPasswordRe,
            current_password: oldPassword,
        };

        axiosInstance.post('/auth/users/set_password/', submitData).then(res => {
            setIsSuccess(true);
        }).catch(error => {
            setErrors(error.response.data);
        })
    };

    const classes = useStyles();
    let messageContent;
    if (isSuccess) {
        messageContent = <Alert elevation={6} variant="filled" severity="success">
            Your password has been modified
        </Alert>
    }


    return (
        <Box hidden={props.hidden}>
            <form className={classes.form} noValidate autoComplete="off">
                {messageContent}
                {
                    (errors.non_field_errors) &&
                    <Alert elevation={6} variant="filled" severity="error">{errors.non_field_errors}</Alert>
                }
                <Grid container justify="space-between" style={{marginTop: 1 + "em"}}>

                    <Grid item sm={12} className={classes.formElem}>
                        <TextField
                            label="Current password"
                            value={oldPassword}
                            fullWidth
                            onChange={event => setOldPassword(event.target.value)}
                            error={ errors.current_password ? true : false}
                            helperText={errors.current_password}
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} className={classes.formElem}>
                        <TextField
                            label="New password"
                            value={newPassword}
                            fullWidth
                            onChange={event => setNewPassword(event.target.value)}
                            error={ errors.new_password ? true : false}
                            helperText={errors.new_password}
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} className={classes.formElem}>
                        <TextField
                            label="New password (repeat)"
                            value={newPasswordRe}
                            fullWidth
                            onChange={event => setNewPasswordRe(event.target.value)}
                            error={ errors.re_new_password ? true : false}
                            helperText={errors.re_new_password}
                            type="password"
                        />
                    </Grid>

                </Grid>
            </form>

            <Button variant="contained" color="primary" onClick={handleSubmit} style={{marginTop: 1+ "em"}}>
                Modify my password
            </Button>
        </Box>
    )


}