import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function ShareDialog (props) {

    const [makePublic, setMakePublic] = useState(false);

    useEffect(() => {
        setMakePublic(props.public);
    }, [props.public]);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={() => props.onClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.children}
                    </DialogContentText>
                    <Grid container justify="space-between">
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={makePublic}
                                        onChange={(event) => setMakePublic(event.target.checked)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Make this quizz public (can be seen by all users on Texquiz)"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(false)} color="secondary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => props.onClose(true, makePublic)} color="primary">
                        Share
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}