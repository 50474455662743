import React, {useContext, useRef, useState} from "react";
import {InlineTex} from "react-tex";
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import {convertCarriageReturns} from "../../utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility} from "@material-ui/icons";
import InputBase from "@material-ui/core/InputBase";
import HelpIcon from '@material-ui/icons/HelpOutline';
import Paper from "@material-ui/core/Paper";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Input from "@material-ui/core/Input";
import {LatexHelpDrawer} from "./LatexHelpDrawer";
import {AuthContext} from "../contexts/AuthContext";
import DrawerContext from "../contexts/DrawerContext";

const useStyles = makeStyles((theme) => ({

        popper: {
            border: '1px solid',
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        }
    }
));

export default function LatexInput(props) {

    const ref = useRef(null);
    const [popperOpen, setPopperOpen] = useState(false);
    const drawerContext = useContext(DrawerContext);

    const classes = useStyles();

    const onFocus = () => {
        setPopperOpen(true);
        if (props.onFocus) props.onFocus();
    }

    const onBlur = () => {
        setPopperOpen(false);
        if (props.onBlur) props.onBlur();
    }

    return (

        <React.Fragment>
            <Input
                required
                ref={ref}
                name={props.name}
                label={props.label}
                value={props.value || ""}
                fullWidth
                rowsMax={4}
                multiline
                autoCorrect="off"
                spellCheck="false"
                autoComplete=""
                placeholder={props.placeholder}
                onChange={event => props.onChange(event.target.value)}
                error={!!props.error}
                onBlur={onBlur}
                onFocus={onFocus}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => drawerContext.dispatch({type: "OPEN"})}>
                            <HelpIcon/>
                        </IconButton>
                    </InputAdornment>
                }
            />
            <Popper style={{zIndex: 2000}} open={popperOpen && props.value && props.value.length > 0}
                    anchorEl={ref.current} placement="bottom-start">
                <div className={classes.popper}>
                    <InlineTex texContent={convertCarriageReturns(props.value)} texSeperator="${1}"/>
                </div>
            </Popper>
        </React.Fragment>
    )

}