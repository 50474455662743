import React, {Component, Fragment, useEffect, useState} from "react";
import {Offline, Online} from "react-detect-offline";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Plans from "./components/plans/Plans";
import Quizz from "./components/quizz/list/Quizz";
import QuizzDetail from "./components/quizz/detail/QuizzDetail";
import CreateQuizz from "./components/quizz/create/CreateQuizz";
import StartQuizz from "./components/quizz/participate/StartQuizz";
import EndQuizz from "./components/quizz/participate/EndQuizz";
import ReviewQuizz from "./components/quizz/participate/ReviewQuizz";
import Signup from "./components/account/signup/Signup";
import Login from "./components/account/login/Login";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import SignupConfirmation from "./components/account/signup/SignupConfirmation";
import Activate from "./components/account/signup/Activate";
import {AuthContext} from "./components/contexts/AuthContext"
import MarketQuizzContext from "./components/contexts/MarketQuizzContext"
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Billing from "./components/Billing/BillingPlan";
import useAxiosInstance from "./api/axiosApi";
import {makeStyles} from '@material-ui/core/styles';
import ConfigContext from "./components/contexts/ConfigContext";
import DrawerContext from "./components/contexts/DrawerContext";
import PasteContext from "./components/contexts/PasteContext";
import ResetPassword from "./components/account/login/ResetPassword";
import Account from "./components/account/Account";
import Market from "./components/market/Market";
import Propose from "./components/market/Propose";
import ProposeConfirmation from "./components/market/ProposeConfirmation";
import RunQuizz from "./components/quizz/participate/RunQuizz";
import 'katex/dist/katex.min.css';
import {LatexHelpDrawer} from "./components/common/LatexHelpDrawer";
import Contact from "./components/contact/Contact";
import ContactConfirmation from "./components/contact/ContactConfirmation";
import BillingConfirmation from "./components/Billing/BillingConfirmation";
import FAQ from "./components/help/FAQ";
import Terms from "./components/help/Terms";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import AccessCodes from "./components/admin/AccessCode";
import CopyQuizz from "./components/admin/CopyQuizz";
import Legal from "./components/help/Legal";
import BillingCheckout from "./components/Billing/BillingCheckout";
import HeaderRunQuizz from "./components/HeaderRunQuizz";


const initialState = {
    isAuthenticated: localStorage.getItem("access_token") != undefined,
    user: JSON.parse(localStorage.getItem("user")),
    token: localStorage.getItem("access_token"),
};

const marketQuizzInitialState = {
    questions: []
};

const drawerInitialState = {
    latexDrawerOpen: false
};

const pasterInitialState = {
    pasteImage: undefined
};

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("user", JSON.stringify(action.user));
            localStorage.setItem("access_token", JSON.stringify(action.token));
            return {
                ...state,
                isAuthenticated: true,
                user: action.user,
                token: action.token
            };
        case "LOGOUT":
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        default:
            return state;
    }
};

const marketQuizzReducer = (state, action) => {
    switch (action.type) {
        case "ADD":
            return {
                ...state, questions: [...state.questions, action.question]
            };
        case "REMOVE":
            const questions = [...state.questions];
            questions.splice(action.index, 1)
            return {
                ...state, questions: questions
            };
        case "REMOVE_ALL":
            return {
                ...state, questions: []
            };
        default:
            return state;
    }
};

const drawerReducer = (state, action) => {
    switch (action.type) {
        case "OPEN":
            return {
                ...state, latexDrawerOpen: true
            };
        case "CLOSE":
            return {
                ...state, latexDrawerOpen: false
            };
        default:
            return state;
    }
};

const pasteReducer = (state, image) => {

    return {
        ...state, pasteImage: image
    };
};

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#f39200",
            contrastText: "#fff"
        },
        secondary: {
            main: blue[500],
        },
    }
});

function App(props) {
    const axiosInstance = useAxiosInstance(props);
    const [loaded, setLoaded] = useState(false);
    const [config, setConfig] = useState({});

    if (!loaded) {
        axiosInstance.get('/api/config')
            .then(response => {
                setConfig(response.data);
                setLoaded(true);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [marketQuizzState, marketQuizzDispatch] = React.useReducer(marketQuizzReducer, marketQuizzInitialState);
    const [drawerState, drawerDispatch] = React.useReducer(drawerReducer, drawerInitialState);
    const [pasteState, pasteDispatch] = React.useReducer(pasteReducer, pasterInitialState);
    const classes = useStyles();


    const onPaste = (event) => {
        var items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (var index in items) {
            var item = items[index];
            if (item.kind === 'file') {
                var fileItem = item.getAsFile();
                const fakeEvent = {
                    dataTransfer: {
                        files: [fileItem],
                        items: [{
                            kind: 'file',
                            type: fileItem.type,
                            getAsFile: () => fileItem
                        }],
                        types: ['Files']
                    }
                };
                pasteDispatch(fakeEvent);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('paste', (event) => {
            onPaste(event);
        });
    }, []);

    return (

        <main>
            <ConfigContext.Provider value={{config}}>
                <MarketQuizzContext.Provider value={{state: marketQuizzState, dispatch: marketQuizzDispatch}}>
                    <AuthContext.Provider value={{state, dispatch}}>
                        <DrawerContext.Provider value={{state: drawerState, dispatch: drawerDispatch}}>
                            <PasteContext.Provider value={{state: pasteState, dispatch: pasteDispatch}}>
                                <ThemeProvider theme={theme}>
                                    <div className={classes.root}>
                                        <Switch>
                                            <Route path="/run_quizz/:code" component={HeaderRunQuizz}/>
                                            <Route component={Header}/>
                                        </Switch>
                                        <Switch>
                                            <Route path="/" component={Home} exact/>
                                            <Route path="/plans" component={Plans}/>
                                            <Route path="/quizz/detail/:code" component={QuizzDetail}/>
                                            <Route path="/quizz" component={Quizz}/>
                                            <Route path="/billing/plan" component={Billing}/>
                                            <Route path="/billing/checkout" component={BillingCheckout}/>
                                            <Route path="/billing/confirmation" component={BillingConfirmation}/>
                                            <Route path="/account/:section" component={Account}/>
                                            <Route path="/create_quizz/:code?" component={CreateQuizz}/>
                                            <Route path="/edit_quizz/:code?" component={CreateQuizz}/>
                                            <Route path="/start_quizz/:code" component={StartQuizz}/>
                                            <Route path="/run_quizz/:code" component={RunQuizz}/>
                                            <Route path="/end_quizz/:code" component={EndQuizz}/>
                                            <Route path="/review_quizz/:code" component={ReviewQuizz}/>
                                            <Route path="/login/:message?" component={Login}/>
                                            <Route path="/signup" component={Signup}/>
                                            <Route path="/signup_confirmation" component={SignupConfirmation}/>
                                            <Route path="/password/reset/confirm/:uid/:token"
                                                   component={ResetPassword}/>
                                            <Route path="/activate/:uid/:token" component={Activate}/>
                                            <Route path="/market/propose" component={Propose}/>
                                            <Route path="/market/confirm" component={ProposeConfirmation}/>
                                            <Route path="/market" component={Market}/>
                                            <Route path="/contact/confirm" component={ContactConfirmation}/>
                                            <Route path="/contact" component={Contact}/>
                                            <Route path="/faq" component={FAQ}/>
                                            <Route path="/terms" component={Terms}/>
                                            <Route path="/legal" component={Legal}/>
                                            <Route path="/q/:code" component={StartQuizz}/>
                                            <Route path="/admin/access_codes" component={AccessCodes}/>
                                            <Route path="/admin/copy_market_quizz" component={CopyQuizz}/>
                                            <Route component={Error}/>
                                        </Switch>
                                        <Footer/>
                                    </div>
                                    <LatexHelpDrawer open={drawerState.latexDrawerOpen}
                                                     onClose={() => drawerDispatch({type: "CLOSE"})}/>
                                </ThemeProvider>
                            </PasteContext.Provider>
                        </DrawerContext.Provider>
                    </AuthContext.Provider>
                </MarketQuizzContext.Provider>
            </ConfigContext.Provider>
        </main>

    );
}

export default App;
