import React, {useContext, useEffect, useState} from "react";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Delete from "@material-ui/icons/Delete";
import LatexInput from "../../common/LatexInput";
import QuestionAnswer, {MemoizedQuestionAnswers} from "./QuestionAnswers";
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import UploadImage, {MemoizedUploadImage} from "../../common/UploadImage";

const useStyles = makeStyles((theme) => ({

        questionCard: {
            backgroundColor: "#f8f8f8"
        },

        questionCardSelected: {
            backgroundColor: "#f8e2cf",

        }
    }
));

export function QuestionCard(props) {

    const classes = useStyles();

    const[explanationFocus, setExplanationFocus] = useState(false);

    const answers = (index) => {
        let content = [];
        for (let i = 1; i <= 4; i++) {
            content.push(
                <MemoizedQuestionAnswers key={i}
                                         question={props.question}
                                         index={index}
                                         i={i}
                                         onAnswerChange={(index, aindex, value) => props.dispatcher({
                                             type: "UPDATE_QUESTION_ANSWER",
                                             index: index,
                                             aindex: aindex,
                                             value: value
                                         })}
                                         onCorrectAnswer={(index, aindex, value) => props.dispatcher({
                                             type: "UPDATE_QUESTION_CORRECT_ANSWER",
                                             index: index,
                                             aindex: aindex
                                         })}/>
            );
        }
        return content;
    };

    return (


        <Card onFocus={() => props.onCardFocus(props.index)} className={classes.questionCard}
              elevation={(props.selectedCard == props.index) ? 6 : 1} key={props.index} style={{marginTop: 2 + "em"}}>
            <CardHeader
                action={
                    (props.multiple) ?
                        <React.Fragment>
                            <IconButton aria-label="settings" onClick={() => props.dispatcher({
                                type: "MOVE_QUESTION_UP",
                                index: props.index
                            })}>
                                <KeyboardArrowUp/>
                            </IconButton>
                            <IconButton aria-label="settings" onClick={() => props.dispatcher({
                                type: "MOVE_QUESTION_DOWN",
                                index: props.index
                            })}>
                                <KeyboardArrowDown/>
                            </IconButton>
                            <IconButton aria-label="settings"
                                        onClick={() => props.dispatcher({type: "DELETE_QUESTION", index: props.index})}>
                                <Delete/>
                            </IconButton>
                        </React.Fragment>
                        :
                        <React.Fragment/>

                }
                title={"Question " + (props.multiple ? (props.index + 1) : "")}
            />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <LatexInput placeholder="Question" onChange={(value) => props.dispatcher({
                            type: "UPDATE_QUESTION_TEXT",
                            index: props.index,
                            value: value
                        })}
                                    value={props.question.question} error={props.question.question_error}/>
                    </Grid>

                    <Grid item sm={9}>
                        {answers(props.index)}
                    </Grid>

                    <Grid item sm={3}>
                        <MemoizedUploadImage onImageChange={(smallImageUrl, bigImageUrl) => props.dispatcher({
                            type: "UPDATE_QUESTION_IMAGE",
                            index: props.index,
                            smallImageUrl: smallImageUrl,
                            bigImageUrl: bigImageUrl
                        })} smallImageUrl={props.question.smallImageUrl} bigImageUrl={props.question.bigImageUrl}
                                             hasFocus={props.selectedCard == props.index && !explanationFocus}/>
                    </Grid>

                    {
                        props.explanation ? (
                                <React.Fragment>
                                    <Grid item sm={9}>
                                        <LatexInput placeholder="Explanation" onBlur={() => setExplanationFocus(false)} onFocus={() => setExplanationFocus(true)} onChange={(value) => props.dispatcher({
                                            type: "UPDATE_EXPLANATION_TEXT",
                                            index: props.index,
                                            value: value
                                        })}
                                                    value={props.question.explanation} error={props.question.explanation_error}/>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <MemoizedUploadImage
                                            onImageChange={(explanationSmallImageUrl, explanationBigImageUrl) => props.dispatcher({
                                                type: "UPDATE_EXPLANATION_IMAGE",
                                                index: props.index,
                                                explanationSmallImageUrl: explanationSmallImageUrl,
                                                explanationBigImageUrl: explanationBigImageUrl
                                            })} smallImageUrl={props.question.explanationSmallImageUrl}
                                            bigImageUrl={props.question.explanationBigImageUrl}
                                            hasFocus={explanationFocus}/>
                                    </Grid>
                                </React.Fragment>
                            )
                            : null
                    }
                </Grid>
            </CardContent>
        </Card>
    );
}

function questionCardPropsAreEqual(prev, next) {
    return prev.index === next.index &&
        prev.question === next.question &&
        prev.selectedCard === next.selectedCard;
}

export const MemoizedQuestionCard = React.memo(QuestionCard, questionCardPropsAreEqual);