import React, {Component, useContext, useEffect, useState} from "react";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useAxiosInstance from "../../../api/axiosApi";
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

export default function ForgotPasswordDialog(props) {

    const axiosInstance = useAxiosInstance(props);

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {

    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axiosInstance.post('/auth/users/reset_password/', {
                email: email,
            });

            props.onClose();

        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            }
        }
    };

    const useStyles = makeStyles((theme) => ({
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
                width: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            }},
        formElem: {
            marginTop: '1em'
        }
    }));

    const classes = useStyles();

    let messageContent;
    if (errors.length>0) {
        messageContent = <Alert elevation={6} variant="filled" severity="error">
            Your login information are not correct or your account is not active.
        </Alert>
    }

    return (

        <div>
            <Dialog
                open={props.open}
                onClose={() => props.onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Forgotten your password?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please enter your email and you will receive an email to reset your password. You may need to check your SPAM folder.
                    </DialogContentText>
                    <Grid container justify="space-between">
                        <Grid item xs={12} sm={12} className={classes.formElem}>
                            <TextField
                                label="Email"
                                value={email}
                                fullWidth
                                autoComplete="on"
                                onChange={event => setEmail(event.target.value)}
                                error={ errors.email  ? true : false}
                                helperText={errors.email}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                    <Button onClick={() => props.onClose()} color="secondary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
