import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useAxiosInstance from "../../api/axiosApi";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Typography from "@material-ui/core/Typography";
import {add, format} from 'date-fns'


const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    formElem: {
        marginTop: '1.5em'
    }
}));

export default function AccessCodes (props) {

    let history = useHistory();

    const axiosInstance = useAxiosInstance(props);

    const [numberCodes, setNumberCodes] = useState("1");
    const [fromDate, setFromDate] = useState(new Date());
    const [responseData, setResponseData] = useState(null);
    const [toDate, setToDate] = useState(new Date('2014-08-18T21:11:54'));

    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        let todayDate = new Date();
        let plusOneYearDate = add(todayDate, {years:1});
        setToDate(plusOneYearDate);
    }, []);


    const validate = () => {
        let errors = {};
        if (isNaN(parseInt(numberCodes))) errors["numberCodes"] = "Please enter a valid number of codes";
        setErrors(errors);
        return !Object.keys(errors).length>0
    };

    const copyToClipboard = async (event) => {
        navigator.clipboard.writeText(responseData.join("\n"));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {

            let submitData = {
                number_codes: parseInt(numberCodes),
                from_date: format(fromDate, "yyyy-MM-dd"),
                to_date: format(toDate, "yyyy-MM-dd")
            };

            try {
                setLoading(true);
                const response = await axiosInstance.post('/api/admin/generate_codes', submitData);
                setResponseData(response.data);
            } catch (error) {
                setErrors(error.response.data);
            }
            setLoading(false);

        }
    };


    const classes = useStyles();

    return (
        <Container maxWidth="md">
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" color="primary" gutterBottom>
                    Generate license codes
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                    <Grid container justify="space-between" style={{marginTop: 1 + "em"}}>


                        <Grid item xs={12} sm={12} className={classes.formElem}>
                            <TextField
                                label="Number of codes"
                                value={numberCodes}
                                onChange={event => setNumberCodes(event.target.value)}
                                error={ errors.numberCodes ? true : false}
                                helperText={errors.numberCodes}
                            />
                        </Grid>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={6} sm={6} className={classes.formElem}>

                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd.MM.yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="From to:"
                                    value={fromDate}
                                    onChange={date => setFromDate(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} className={classes.formElem}>

                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd.MM.yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Valid to:"
                                    value={toDate}
                                    onChange={date => setToDate(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </form>
                <Button disabled={isLoading} variant="contained" color="primary" onClick={handleSubmit} style={{marginTop: 1+ "em"}}>
                    Generate
                </Button>
                <Box style={{marginTop: 2+ "em"}} hidden={!isLoading}>Loading...</Box>
                { responseData ?
                    <React.Fragment>
                        <TextField
                            style={{marginTop: 2+ "em"}}
                            label="License codes"
                            value={responseData.join("\n")}
                            multiline
                            rows={10}
                            fullWidth
                        />
                        <Button  variant="contained" color="secondary" onClick={copyToClipboard} style={{marginTop: 1+ "em"}}>
                            Copy to clipboard
                        </Button>
                    </React.Fragment>
                    : null
                }

            </Paper>
        </Container>
    )


}
