import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import useAxiosInstance from "../../api/axiosApi";
import {FAQ_CATEGORIES_URL, LATEX_CATEGORIES_URL, TERMS_CATEGORIES_URL} from "../../constants";
import {convertCarriageReturns} from "../../utils";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function FAQ (props) {

    const axiosInstance = useAxiosInstance(props);
    const [data, setData] = React.useState([]);

    const classes = useStyles();

    useEffect( () => {
        let data = [];
        axiosInstance.get(TERMS_CATEGORIES_URL).then(res => {
            setData(res.data);
        });
    }, []);

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    Terms and conditions
                </Typography>
                <p>
                    You will find here below the terms and conditions that applies to this service. If you have any question, please contact us.
                </p>

                {
                    data.map((entry, index) => (
                        <React.Fragment key={index}>
                            <Typography variant="subtitle1" color="primary" style={{marginBottom: 1 + "em", marginTop: 2+"em"}}>
                                {entry.title}
                            </Typography>
                            <Typography><div dangerouslySetInnerHTML={{__html: convertCarriageReturns(entry.text)}}/></Typography>
                        </React.Fragment>
                    ))
                }

            </Paper>
        </Container>
    );

}