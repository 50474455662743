import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      <Link color="inherit" href="https://www.texquiz.com/">
        TeXQuiz.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 2+'em',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
      <footer className={classes.footer}>
        <Container maxWidth="md" style={{textAlign: 'center'}}>
          <Link component={RouterLink} to="/legal">Legal notice</Link>&nbsp;&nbsp;
          <Link component={RouterLink} to="/terms">Terms & conditions</Link>&nbsp;&nbsp;
          <Link component={RouterLink} to="/faq">FAQ</Link>&nbsp;&nbsp;
          <Link component={RouterLink} to="/contact">Contact</Link>
          <Copyright />
        </Container>
      </footer>
  );
}