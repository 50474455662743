
import React, {useContext, useEffect, useRef, useState} from "react";
import '../../css/card.css';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import TiersData from '../../data/plansData.js';
import PlansCards from "../plans/PlansCards";
import {addYears, format} from "date-fns";
import ConfigContext from "../contexts/ConfigContext";
import TextField from "@material-ui/core/TextField";
import {AuthContext} from "../contexts/AuthContext";
import useAxiosInstance from "../../api/axiosApi";
import {useHistory} from "react-router-dom";


export default function BillingPlan (props) {

    const [transactionId, setTransactionId] = useState(undefined);
    const [currency, setCurrency] = useState("CHF");
    const [selectedTier, setSelectedTier] = useState(0);
    const [licenseType, setLicenseType] = useState("personal");
    const [numberLicenses, setNumberLicenses] = useState(1);
    const [checkboxHandler, setCheckboxHandler] = useState(undefined);
    const authContext = useContext(AuthContext);

    const tiers = TiersData().filter(tier => tier.price.CHF > 0);
    const { config } = useContext(ConfigContext);
    const axiosInstance = useAxiosInstance(props);

    let history = useHistory();

    const useStyles = makeStyles((theme) => ({

        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            }},
        billingTable: {
            width:"100%",
            marginTop: 2+"em",
            "& td": {
                height: 40+"px"
            }
        }
    }));

    const classes = useStyles();

    const onSelectCard = (index) => {
        setSelectedTier(index);
    };

    const getPrice = () => {
        return (numberLicenses - Math.floor((numberLicenses/5)))*tiers[selectedTier].price[currency];
    };

    const submitForm = () => {
        history.push("/billing/checkout", {currency: currency});
    };

    return (
        <Container maxWidth="md" component="main" style={{marginTop: 2+"em"}}>
            <Paper className={classes.paper}>
                <Typography variant="h5" color="primary" gutterBottom>
                    Buy a plan
                </Typography>
                <PlansCards tiers={tiers} showSubheader={false} selectable={true} onChange={onSelectCard}/>
                <table className={classes.billingTable}>
                    <tbody>

                    <tr>
                        <td>Currency</td>
                        <td style={{float:"right"}}>
                            <Select labelId="org-type-label"
                                    fullWidth value={currency}
                                    onChange={(event) => setCurrency(event.target.value)}
                            >
                                <MenuItem value="CHF">CHF</MenuItem>
                                <MenuItem value="EUR">EUR</MenuItem>
                            </Select>
                        </td>
                    </tr>

                    <tr>
                        <td>License type
                            <Tooltip title="Personal: Buy one personal code associated to your account.">
                                <IconButton aria-label="info">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </td>
                        <td style={{float:"right"}}>
                            Personal

                        </td>
                    </tr>
                    <tr>
                        <td>License tier</td>
                        <td style={{float:"right"}}>{tiers[selectedTier].title}</td>
                    </tr>
                    <tr>
                        <td>Monthly price</td>
                        <td style={{float:"right"}}>{tiers[selectedTier].price[currency]} {currency}/year</td>
                    </tr>

                    <tr>
                        <td>Valid until</td>
                        <td style={{float:"right"}}>{format(addYears(new Date(), 1),"dd.M.yyyy")}</td>
                    </tr>
                    <tr>
                        <td>Total one year</td>
                        <td style={{float:"right", fontWeight:"bold"}}>{getPrice()} {currency}</td>
                    </tr>
                    </tbody>
                </table>

                <Button color="primary" variant="contained" onClick={submitForm}>
                    Proceed to checkout
                </Button>
                <br/>
                <img style={{clear:"both", marginTop: 2+"em", marginLeft:"auto", height: "40px"}} src="/texquiz_modes_paiement.png"/>
            </Paper>
        </Container>
    );
}
