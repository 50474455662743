import React, {Component, createContext, useContext, useEffect, useReducer, useRef, useState} from "react";
import useAxiosInstance from "../../../api/axiosApi";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SettingsIcon from '@material-ui/icons/Settings';
import {CreateQuizzDrawer} from "./CreateQuizzDrawer";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import MarketQuizzContext from "../../contexts/MarketQuizzContext";
import {AuthContext} from "../../contexts/AuthContext";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import uuidv1 from  'uuid/v1';
import {QUIZZ_URL, THEMES_URL} from "../../../constants";
import {MemoizedQuestionCard} from "./QuestionCard";
import {Link as RouterLink} from 'react-router-dom';
import quizzReducer from "./QuizzReducer";
import useStickyReducer from "../../utils/StickyState";

const marks = [
    {
        value: 1,
        label: '1 mn',
    },
    {
        value: 15,
        label: '15 mn',
    },
    {
        value: 60,
        label: '60 mn',
    },
    {
        value: 120,
        label: '120 mn',
    },
    {
        value: 240,
        label: '240 mn',
    },
];

const QUIZZ_CREATE_SAVE_KEY = "quizz_create_save";

function valuetext(value) {
    return `${value} mn`;
}

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    }}));

export const emptyQuestion = () => {
    return {
        "id": uuidv1(),
        "question": "",
        "answer1": "",
        "answer2": "",
        "answer3": "",
        "answer4": "",
        "correctAnswer": 1,
        "smallImageUrl": "",
        "bigImageUrl": "",
        "explanation": "",
        "explanationSmallImageUrl": "",
        "explanationBigImageUrl": ""
    }
};

const validate = (questions, questionsDispatch) => {
    let valid = true;
    questions.forEach((question, index) => {
        let fieldsToValidate = ["question", "answer1", "answer2"];
        fieldsToValidate.forEach(field => {
            if (question[field].length === 0) {
                questionsDispatch({
                    type: "SET_QUESTION_ERROR",
                    index: index,
                    field: field,
                    value: "This field cannot be empty"
                });
                valid = false;
            } else {
                questionsDispatch({
                    type: "SET_QUESTION_ERROR",
                    index: index,
                    field: field,
                    value: null
                });
            }
        });
    });
    return valid;
};

const CreateQuizz = (props) => {

    const axiosInstance = useAxiosInstance(props);
    const marketQuizzContext = useContext(MarketQuizzContext);
    const authContext = useContext(AuthContext);

    const [quizz, quizzDispatch] = useStickyReducer((props.location.state && props.location.state.importFromMarket),{
        "questions": [emptyQuestion()],
        "isAnonymous": true,
        "isRandomQuestions": false,
        "isRandomAnswers": false,
        "areAnswersAvailable": true,
        "isCommentAllowed": true,
        "isRatingEnabled": true
    }, QUIZZ_CREATE_SAVE_KEY, quizzReducer);
    const [themesList, setThemesList] = useState([]);
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [editMode, setEditMode] = useState(false);
    const [selectedCard, setSelectedCard] = useState(0);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const updateProperty = (key, value) => {
        quizzDispatch({"type": "UPDATE_PROPERTY", "key": key, "value": value})
    };

    const getThemes = () => {
        let themes = [{
            "id": 0,
            "name": "---None---",
        }];
        axiosInstance.get(THEMES_URL).then(res => {
            res.data.map((theme) => {
                themes.push(
                    {
                        "id": theme.id,
                        "name": theme.name,
                    }
                );

            });
            setThemesList(themes);
        });
    };

    useEffect(() => {
        getThemes();
    }, []);

    const onCardFocus = (cardIndex) => {
        setSelectedCard(cardIndex);
    };

    const handleSubmit = async (event) => {
        const questionsData = [];
        if (validate(quizz.questions, quizzDispatch)) {
            quizz.questions.forEach(question => {
                questionsData.push({
                    text: question["question"],
                    answer1: question["answer1"],
                    answer2: question["answer2"],
                    answer3: question["answer3"],
                    answer4: question["answer4"],
                    smallImageUrl: question["smallImageUrl"],
                    bigImageUrl: question["bigImageUrl"],
                    correct_answer: question["correctAnswer"],
                });
            });
            const data = {
                title: quizz.title,
                description: quizz.description,
                duration: quizz.duration,
                number_students: quizz.numberStudents,
                anonymous: quizz.isAnonymous,
                random_questions: quizz.isRandomQuestions,
                random_answers: quizz.isRandomAnswers,
                comment_allowed: quizz.isCommentAllowed,
                answers_available: quizz.areAnswersAvailable,
                rating_enabled: quizz.isRatingEnabled,
                questions: questionsData,
            };

            if (editMode) {
                axiosInstance.patch('/api/quizz/'+props.match.params.code, data)
                    .then(response => {
                        window.localStorage.removeItem(QUIZZ_CREATE_SAVE_KEY);
                        props.history.push("/quizz");
                    })
                    .catch(error => {
                        setErrors(error.response.data);
                    });
            }
            else {
                axiosInstance.post('/api/quizz', data)
                    .then(response => {
                        window.localStorage.removeItem(QUIZZ_CREATE_SAVE_KEY);
                        props.history.push("/quizz");
                    })
                    .catch(error => {
                        setErrors(error.response.data);
                    });
            }
        }
    };

    const classes = useStyles();

    const addQuestion = () => {
        quizzDispatch({
            type: "ADD_QUESTION",
            question: emptyQuestion()
        });
        setSelectedCard(quizz.questions.length);
    };

    const appendMarketQuestions = (marketQuestions) => {
        let mergeQuestions = [];
        marketQuestions.forEach(question => {
            let newQuestion = {};
            newQuestion.question = question.text || "";
            newQuestion.answer1 = question.answer1 || "";
            newQuestion.answer2 = question.answer2 || "";
            newQuestion.answer3 = question.answer3 || "";
            newQuestion.answer4 = question.answer4 || "";
            newQuestion.correctAnswer = question.correctAnswer || "";
            newQuestion.smallImageUrl = question.smallImageUrl || "";
            newQuestion.bigImageUrl = question.bigImageUrl || "";
            mergeQuestions.push(newQuestion);
        });
        quizzDispatch({
            type: "ADD_QUESTIONS",
            questions: mergeQuestions
        });
    };

    useEffect(() => {
        if (themesList.length>0) {
            if (props.match.params.code) {
                axiosInstance.get(QUIZZ_URL+"/"+props.match.params.code).then(res => {

                    let theme_id = 0;
                    try{
                        theme_id = themesList.filter(t => t.name===res.data.theme)[0].id;
                    }
                    catch (e) {

                    }
                    setEditMode(true);
                    quizzDispatch({
                        "type": "INIT",
                        "value": {
                            "title": res.data.title,
                            "description": res.data.description,
                            "duration": res.data.duration,
                            "isRandomQuestions": res.data.random_questions,
                            "isRandomAnswers": res.data.random_answers,
                            "isAnonymous": res.data.anonymous,
                            "numberOfStudents": res.data.number_students,
                            "areAnswersAvailable": res.data.answers_available,
                            "isCommentAllowed": res.data.comment_allowed,
                            "isRatingEnabled": res.data.rating_enabled,
                            "questions": []
                        }
                    });

                    let questions = [];
                    res.data.questions.forEach((question) => {
                        questions.push({
                            question: question.text || "",
                            answer1: question.answer1 || "",
                            answer2: question.answer2 || "",
                            answer3: question.answer3 || "",
                            answer4: question.answer4 || "",
                            smallImageUrl: question.smallImageUrl || "",
                            bigImageUrl: question.bigImageUrl || "",
                            correctAnswer: question.correct_answer || "",
                        });
                    });
                    quizzDispatch({
                        type: "SET_QUESTIONS",
                        questions: questions
                    });
                });
            } else if (props.location.state && props.location.state.importFromMarket) {
                let marketQuestions = marketQuizzContext.state.questions;
                if (marketQuestions.length > 0) {
                    appendMarketQuestions(marketQuestions);
                }
                marketQuizzContext.dispatch({"type": "REMOVE_ALL"})
            }
        }
    }, [themesList]);


    return (



        <main className={classes.layout}>
            <CreateQuizzDrawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                isAnonymous={quizz.isAnonymous}
                setIsAnonymous={(value) =>  updateProperty("isAnonymous", value)}
                isRandomQuestions={quizz.isRandomQuestions}
                setIsRandomQuestions={(value) =>  updateProperty("isRandomQuestions", value)}
                isRandomAnswers={quizz.isRandomAnswers}
                setIsRandomAnswers={(value) =>  updateProperty("isRandomAnswers", value)}
                areAnswersAvailable={quizz.areAnswersAvailable}
                setAreAnswersAvailable={(value) =>  updateProperty("areAnswersAvailable", value)}
                numberStudents={quizz.numberStudents}
                setNumberOfStudents={(value) =>  updateProperty("numberStudents", value)}
                isCommentAllowed={quizz.isCommentAllowed}
                setIsCommentAllowed={(value) =>  updateProperty("isCommentAllowed", value)}
                isRatingEnabled={quizz.isRatingEnabled}
                setIsRatingEnabled={(value) =>  updateProperty("isRatingEnabled", value)}
            />

            <Grid container spacing={3}>
                { errors.questions ?
                    <Grid item xs={12} sm={12} style={{paddingTop:2+"em"}}>
                        <Alert elevation={6} variant="filled" severity="error">
                            Your quizz questions are invalid.
                        </Alert>
                    </Grid>
                    :
                    <React.Fragment/>
                }

                <Grid item xs={12} sm={12} style={{paddingTop:2+"em"}}>
                    <TextField
                        id="title"
                        name="title"
                        required
                        placeholder="Enter the title of your quizz"
                        label="Title"
                        value={quizz.title || ''}
                        variant="outlined"
                        fullWidth
                        error={ errors.title ? true : false}
                        helperText={errors.title}
                        inputProps={{ maxLength: 60 }}
                        onChange={(event) => updateProperty("title", event.target.value)}
                        autoComplete={false}
                    />
                </Grid>
                <Grid item xs={12} sm={12} style={{paddingTop:2+"em"}}>
                    <TextField
                        id="description"
                        name="description"
                        placeholder="Enter the description of your quizz"
                        label="Description"
                        value={quizz.description || ''}
                        variant="outlined"
                        fullWidth
                        error={ errors.description ? true : false}
                        helperText={errors.description}
                        inputProps={{ maxLength: 80 }}
                        onChange={(event) => updateProperty("description", event.target.value)}
                        autoComplete={false}
                    />
                </Grid>
                <Grid item sm={12} style={{width:"100%"}}>
                    <Typography id="duration-slider" gutterBottom>
                        Duration
                    </Typography>
                    <Slider
                        defaultValue={15}
                        getAriaValueText={valuetext}
                        aria-labelledby="duration-slider"
                        step={1}
                        min={1}
                        max={240}
                        value={parseInt(quizz.duration)}
                        onChange={(event, newValue) => updateProperty("duration", newValue)}
                        valueLabelDisplay="auto"
                        marks={marks}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Button variant="contained"  color="secondary" size="small" startIcon={<SettingsIcon />} onClick={(event) => setDrawerOpen(true)}>
                        More options
                    </Button>
                </Grid>
            </Grid>
            {
                quizz.questions.map((question, index) => (
                    <div key={question.id}>
                        <MemoizedQuestionCard index={index} question={question} dispatcher={quizzDispatch}
                                              multiple={true} onCardFocus={onCardFocus} selectedCard={selectedCard}/>
                    </div>
                ))
            }

            <Grid container justify="space-between" style={{marginTop: 2 + "em"}}>
                <Grid item sm={9} xs={12}>
                    <Button variant="contained" color="secondary" onClick={addQuestion} style={{marginRight:1+"em", marginBottom:1+"em"}}>
                        Add a question
                    </Button>
                    <Button component={RouterLink} variant="contained" color="secondary" to="/market"
                            style={{marginRight: 1 + "em", marginBottom: 1 + "em"}}>
                        Add questions from Market Quizz
                    </Button>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <div align="right">
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </Grid>
            </Grid>

            <Dialog
                open={!!errorMessage}
                onClose={() => setErrorMessage(undefined)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Error</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errorMessage}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </main>

    );
};

export default CreateQuizz;
