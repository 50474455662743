export default function TiersData()
{
    return [
        {
            title: 'Pro',
            code: 'P',
            period: 'year',
            price: {
                EUR: '49',
                CHF: '49'
            },
            description: [
                {
                    text: 'Unlimited questions per quizz'
                },
                {
                    text: 'Unlimited students'
                },
                {
                    text: 'Additional options',
                    tooltip: 'Randomized quizz<br/>Anonymous<br/>Comments allowed'
                },
                {
                    text: 'Market quizz',
                    tooltip: 'Access to thousands predefined and modifiable questions'
                },
                {
                    text: 'Share quizz',
                    tooltip: 'Share your quizz with your colleagues'
                },
                {
                    text: 'Unlimited active quizzes',
                    tooltip: 'More than 5 active quizzes'
                },
            ],
            buttonText: 'Get your license now',
            buttonLink: '/account/billing',
            buttonVariant: 'outlined',
        },
        {
            title: 'Free',
            code: '',
            period: 'Year',
            price: {
                CHF: '0',
                EUR: '0'
            },
            description: [
                {
                    text: 'Unlimited questions per quizz'
                },
                {
                    text: 'Unlimited students'
                },
                {
                    text: 'Additional options',
                    tooltip: 'Randomized quizz<br/>Anonymous<br/>Comments allowed'
                },
                {
                    text: 'Market quizz',
                    tooltip: 'Access to thousands predefined and modifiable questions'
                },
                {
                    text: 'Share quizz',
                    tooltip: 'Share your quizz with your colleagues'
                },
            ],
            buttonText: 'Sign up for free',
            buttonLink: 'signup',
            buttonVariant: 'outlined',
        }
    ];
}