export const API_URL = "http://localhost:8000/api/students/";
export const QUIZZ_URL = "api/quizz";
export const IMPORT_QUIZZ_URL = "api/quizz/_import";
export const REMOVE_PUBLIC_QUIZZ_URL = "api/quizz/_removepublic";
export const MARKET_SEARCH_URL = "api/market/quizz/_search";
export const QUIZZ_SEARCH_URL = "api/quizz/_search";
export const MARKET_URL = "api/market/quizz";
export const THEMES_URL = "api/themes";
export const LEVELS_URL = "api/levels";
export const TYPES_URL = "api/types";
export const LATEX_CATEGORIES_URL = "api/latex/categories";
export const FAQ_CATEGORIES_URL = "api/faq/categories";
export const TERMS_CATEGORIES_URL = "api/terms";

