import React, {Component, useEffect, useState} from "react";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Alert from "@material-ui/lab/Alert";
import {useHistory} from "react-router-dom";
import Box from "@material-ui/core/Box";
import useAxiosInstance from "../../api/axiosApi";
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact (props) {

    let history = useHistory();

    const axiosInstance = useAxiosInstance(props);

    const recaptchaRef = React.createRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [recaptchaError, setRecaptchaError] = useState(false);

    const [errors, setErrors] = useState({});

    const useStyles = makeStyles((theme) => ({
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
                width: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            }},
        formElem: {
            marginTop: '1.5em'
        }
    }));

    function onChange(value) {
    }

    const validate = () => {
        let errors = {};
        if (!message) errors["message"] = "Please enter a message";
        if (!name) errors["name"] = "Please enter a name";
        if (!phone) errors["phone"] = "Please enter a phone";
        setErrors(errors);
        return !Object.keys(errors).length>0
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            setRecaptchaError(true);
        }
        if (validate() && recaptchaValue) {

            let submitData = {
                name: name,
                email: email,
                phone: phone,
                message: message,
                recaptcha: recaptchaValue
            };


            try {
                axiosInstance.post('/api/contact', submitData).then(res => {
                    history.push("/contact/confirm");
                });
            } catch (error) {

            }
        }
    };

    const classes = useStyles();

    return (
        <Container maxWidth="md">
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    Contact us
                </Typography>
                <p>
                    We are here to provide you with more information and assist you.
                </p>
                <Box hidden={props.hidden}>
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>

                        <Grid container justify="space-between" style={{marginTop: 1 + "em"}}>

                            <Grid item xs={12} className={classes.formElem}>
                                <TextField
                                    label="Name"
                                    value={name}
                                    fullWidth
                                    required
                                    onChange={event => setName(event.target.value)}
                                    error={ errors.name ? true : false}
                                    helperText={errors.name}
                                />
                            </Grid>


                            <Grid item xs={12} className={classes.formElem}>
                                <TextField
                                    label="Email"
                                    fullWidth
                                    value={email}
                                    required
                                    error={ errors.email ? true : false}
                                    helperText={errors.email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.formElem}>
                                <TextField
                                    label="Phone"
                                    value={phone}
                                    fullWidth
                                    error={ errors.phone ? true : false}
                                    helperText={errors.phone}
                                    onChange={event => setPhone(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.formElem}>
                                <TextField
                                    label="Message"
                                    value={message}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    required
                                    error={ errors.message ? true : false}
                                    helperText={errors.message}
                                    onChange={event => setMessage(event.target.value)}
                                />

                            </Grid>

                        </Grid>


                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LfS00gaAAAAALvi4ca2vQdUy5UmF-yHCco1-WOi"
                            style={{ marginTop: 2 + "em" }}
                            onChange={onChange}
                        />
                        {
                            recaptchaError && (
                                <Alert severity="error">Please validate the captcha.</Alert>

                            )
                        }
                        <Button type="submit" variant="contained" color="primary" style={{marginTop: 2 + "em"}}>
                            Submit
                        </Button>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
}

