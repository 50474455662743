import React, {useEffect, useState} from "react";
import useAxiosInstance from "../../api/axiosApi";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ActivateLicenseDialog from "./ActivateLicenseDialog";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const licenseTiers = {"P": "Pro", "F": "Free"};

export default function License(props) {

    const axiosInstance = useAxiosInstance(props);

    const [licensesList, setLicensesList] = useState([]);
    const [mainLicense, setMainLicense] = useState({});
    const [activateDialogOpen, setActivateDialogOpen] = useState(false);
    const [isLoading, setLoading] = useState(true);


    useEffect(() => {
        loadLicenses();
    }, []);

    const loadLicenses = () => {
        try {
            axiosInstance.get('/api/license?active=true').then(response => {
                setLicensesList(response.data);
                setMainLicense(response.data[0]);
                setLoading(false);
            });

        }
        catch(error) {

        }
    };

    const closeActivateDialog = (success) => {
        setActivateDialogOpen(false);
        if (success) {
            loadLicenses();
        }
    };

    const classes = useStyles();

    if (isLoading) {
        return  <Box hidden={props.hidden}>Loading...</Box>;
    }

    return (
        <Box hidden={props.hidden}>

            {
                mainLicense ? (
                    <Grid container justify="center" alignItems="center" style={{marginTop: 1 + "em"}}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Your current licenses
                            </Typography>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell align="center">Tier</TableCell>
                                        <TableCell align="right">Valid from</TableCell>
                                        <TableCell align="right">Valid to</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {licensesList.map((license) => (
                                        <TableRow key={license.code}>
                                            <TableCell component="th" scope="row">
                                                {license.code}
                                            </TableCell>
                                            <TableCell align="center">{licenseTiers[license.tier]}</TableCell>
                                            <TableCell align="right">{license.valid_from}</TableCell>
                                            <TableCell align="right">{license.valid_to}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justify="center" alignItems="center" style={{marginTop: 1 + "em"}}>
                        <Grid item xs={6} style={{textAlign:"center"}}>
                            <Typography variant="h6" gutterBottom>
                                Upgrade to the Pro plan
                            </Typography>
                            <CheckCircleOutline style={{fontSize: 100+"px"}}/>
                            <br/>
                            Access to thousands predefined and modifiable question, share your quizz, unlimited quizz questions, unlimited students and more.
                            <br/><br/>
                            <Button component={RouterLink} color="primary" variant="contained" to="/billing/plan">
                                Upgrade to Pro plan
                            </Button>
                            <br/>
                        </Grid>
                    </Grid>
                )
            }
            <Grid container justify="center" alignItems="center" style={{marginTop: 1 + "em"}}>
                <Grid item xs={6} style={{textAlign:"center"}}>
                    <br/>
                    <hr/>
                    <br/>
                    <Button color="secondary" variant="contained" onClick={() => setActivateDialogOpen(true)}>
                        Activate a license
                    </Button>
                    <ActivateLicenseDialog open={activateDialogOpen} onClose={closeActivateDialog}/>
                </Grid>
            </Grid>

        </Box>
    )


}