import React, {Component, useContext, useEffect} from "react";
import {AuthContext} from "./contexts/AuthContext"
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Menu from "@material-ui/core/Menu";
import {AccountCircle} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import {hasAnyLicense, hasLicense, isStaff} from "../utils";
import { Link as RouterLink } from 'react-router-dom';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Alert from "@material-ui/lab/Alert";
import SettingsIcon from '@material-ui/icons/Settings';
import useAxiosInstance from "../api/axiosApi";

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function HeaderRunQuizz(props, context) {

  const auth = useContext(AuthContext);
  const axiosInstance = useAxiosInstance(props);
  let history = useHistory();


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
  const [licenseInfoOpen, setLicenseInfoOpen] = React.useState(false);
  const [mainLicenseLoaded, setMainLicenseLoaded] = React.useState(false);
  const [mainLicense, setMainLicense] = React.useState(null);
  const [daysLeft, setDaysLeft] = React.useState(0);


  const classes = useStyles();

  useEffect(() => {
    if (auth.state.isAuthenticated) loadLicenses();
    else {
      setMainLicense(null);
    }
  }, [auth.state.isAuthenticated]);

  const loadLicenses = () => {
    try {
      axiosInstance.get('/api/license?active=true').then(response => {
        setMainLicenseLoaded(true);
        setMainLicense(response.data[0]);
        if (response.data[0]) {
          let valid_to = Date.parse(response.data[0].valid_to);
          let today = new Date();
          let diff_days = Math.ceil((valid_to - today) / (1000 * 60 * 60 * 24));
          if (diff_days < 0) diff_days = 0;
          setDaysLeft(diff_days);
        }
      });

    }
    catch(error) {

    }
  };

  const handleAccountMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdminMenu = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };

  const handleAdminClose = () => {
    setAdminAnchorEl(null);
  };

  const handleAccountClose = () => {
    setAnchorEl(null);
  };

  const profile = () => {
    history.push("/account/profile");
    handleAccountClose();
  };

  const accessCodes = () => {
    history.push("/admin/access_codes");
    handleAdminClose();
  };

  const license = () => {
    history.push("/account/license");
    handleAccountClose();
  };

  const logout = () => {
    auth.dispatch({
      type: "LOGOUT"
    });
    history.push('/login');
    handleAccountClose();
  };

  const handlePlansClick = () => {
    history.push("/plans");
    setLicenseInfoOpen(false);
  };

  const handleNoLicense = () => {
    setLicenseInfoOpen(true);
  };

  return(
      <AuthContext.Consumer>
        {context => (
            <AppBar position="fixed" color="default" elevation={0} className={classes.appBar}>
              <Toolbar className={classes.toolbar}>

                <Dialog
                    open={licenseInfoOpen}
                    onClose={() => setLicenseInfoOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Activate your license</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <p>You do not have an active license.</p>
                      <Button component={RouterLink} variant="contained" color="primary" onClick={handlePlansClick}>
                        Get the Pro version
                      </Button>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              </Toolbar>
            </AppBar>
        )}
      </AuthContext.Consumer>
  );

}
