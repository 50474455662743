import React, {useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Profile from "./Profile";
import License from "./License";
import Password from "./Password";

const useStyles = makeStyles((theme) => ({

    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    billingTable: {
        width:"100%",
        marginTop: 2+"em",
        "& td": {
            height: 40+"px"
        }
    }
}));

export default function Account (props) {


    const classes = useStyles();
    const [index, setIndex] = React.useState(0);

    useEffect(() => {
        switch (props.match.params.section) {
            case "profile":
                setIndex(0);
                break;

            case "license":
                setIndex(1);
                break;

            case "password":
                setIndex(2);
                break;

            default:
                setIndex(0);
                break;
        }

    }, props.match.params.section);

    return (

        <Container maxWidth="md" component="main" style={{marginTop: 2+"em"}}>
            <Paper className={classes.paper}>
                <Typography variant="h5" color="primary" gutterBottom>
                    Account
                </Typography>
                <Tabs value={index} onChange={(event, newValue) => setIndex(newValue)} aria-label="Account features">
                    <Tab label="Profile" />
                    <Tab label="License"/>
                    <Tab label="Password"/>

                </Tabs>
                <Profile hidden={index !== 0} editMode={true}/>
                <Password hidden={index !== 2}/>
                <License hidden={index !== 1}/>

            </Paper>
        </Container>
    )

}