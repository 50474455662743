import React, {useEffect, useReducer} from "react";

async function updateLocalStorage(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function useStickyReducer(restoreState, defaultValue, key, reducer) {
  const [value, dispatcher] = useReducer(reducer, defaultValue, (value) => {
    const stickyValue = window.localStorage.getItem(key);
    return (stickyValue !== null && restoreState)
        ? JSON.parse(stickyValue)
        : value;
  });

  useEffect(() => {
    updateLocalStorage(key, value);
  }, [value]);

  return [value, dispatcher];
}

export default useStickyReducer;