export function getStatus(status) {
    switch (status) {
        case 0:
            return "NOT STARTED";
        case 1:
            return "STARTED";
        case 2:
            return "CLOSED"
    }
    return status;
}

export function getLetter(index) {
    const letters = ["A", "B", "C", "D"];
    return letters[index];

}

export function shuffleArray(d) {
    for (var c = d.length - 1; c > 0; c--) {
        var b = Math.floor(Math.random() * (c + 1));
        var a = d[c];
        d[c] = d[b];
        d[b] = a;
    }
    return d
}

export function convertCarriageReturns(s) {
    if (!s) return "";
    var pattern = new RegExp("\\$(.*?)\\$", "gs");
    var html = s.replace(pattern, function (x) {
        return x.replace(new RegExp('\r?\n','g'), ' ');
    });
    return html.replace(new RegExp('\r?\n','g'), '<br />');
}

export function hasLicense(user, license) {
    return user && user.user_permissions && user.user_permissions.includes(license);
}

export function hasAnyLicense(user) {
    return user;
}

export function isStaff(user) {
    return user && user.user_permissions && (user.user_permissions.includes("STAFF"));
}