import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import useAxiosInstance from "../../api/axiosApi";
import {AuthContext} from "../contexts/AuthContext";
import {useHistory} from "react-router-dom";


export default function ConfirmDeleteDialog (props) {
    const axiosInstance = useAxiosInstance(props);
    const auth = useContext(AuthContext);
    let history = useHistory();

    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    const handleDelete = async (event) => {
        event.preventDefault();
        let submitData = {
            current_password: password,
        };

        axiosInstance.delete('/auth/users/me/', {data: submitData}).then(res => {
            auth.dispatch({
                type: "LOGOUT"
            });
            history.push('/login/delete');
        }).catch(error => {
            setErrors(error.response.data);
        })
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={() => props.onClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Deletion of your account</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you really want to delete your account? This is an irreversible action!
                    </DialogContentText>
                    <TextField
                        label="Enter your password"
                        value={password}
                        fullWidth
                        onChange={event => setPassword(event.target.value)}
                        error={ errors.current_password ? true : false}
                        helperText={errors.current_password}
                        type="password"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}