import React, {Component, useContext, useEffect, useState} from "react";
import useAxiosInstance from "../../../api/axiosApi";
import { makeStyles } from '@material-ui/core/styles';
import Alert from "@material-ui/lab/Alert";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},

}));

export default function Activate(props) {
    const axiosInstance = useAxiosInstance(props);

    const [invalidToken, setInvalidToken] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [errors, setErrors] = useState({});


    useEffect(() => {
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newPassword!==newPasswordConfirm) {
            setErrors({re_new_password: "Passwords are not identical"});
            return;
        }
        try {
            const response = await axiosInstance.post('/auth/users/reset_password_confirm/', {
                uid: props.match.params.uid,
                token: props.match.params.token,
                new_password: newPassword,
                re_new_password: newPasswordConfirm
            });
            props.history.push("/login/reset");
        } catch (error) {
            if (error.response && error.response.data && (error.response.data["new_password"] || error.response.data["re_new_password"])) {
                setErrors(error.response.data);
            } else {
                setInvalidToken(true);
                setErrors({});
            }
        }
    };

    const classes = useStyles();
    if (invalidToken) {
        var invalidTokenMessage =
            <Alert elevation={6} variant="filled" severity="error">
                Impossible to activate your account. Your link is maybe not anymore valid. Please contact the support.
            </Alert>

    }
    return (
        <Container maxWidth="sm">
            <Paper className={classes.paper}>
                {invalidTokenMessage}

                <Typography variant="h5" gutterBottom color="primary">
                    Set a new password
                </Typography>
                <p>Please enter a new password.</p>

                <form noValidate autoComplete="on" onSubmit={handleSubmit}>

                    <Grid container justify="space-between">
                        <Grid item xs={12} sm={12} className={classes.formElem}>
                            <TextField
                                label="Password"
                                value={newPassword}
                                type="password"
                                fullWidth
                                autoComplete="on"
                                onChange={event => setNewPassword(event.target.value)}
                                error={ errors.new_password  ? true : false}
                                helperText={errors.new_password}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} className={classes.formElem}>
                            <TextField
                                label="Password (Confirmation)"
                                value={newPasswordConfirm}
                                type="password"
                                fullWidth
                                autoComplete="on"
                                onChange={event => setNewPasswordConfirm(event.target.value)}
                                error={ errors.re_new_password  ? true : false}
                                helperText={errors.re_new_password}
                            />
                        </Grid>

                    </Grid>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} style={{marginTop: 2 + "em", marginRight: 1+"em"}}>
                        Confirm
                    </Button>
                </form>
            </Paper>
        </Container>
    )
}