import React, {useContext, useEffect, useRef, useState} from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import useAxiosInstance from "../../api/axiosApi";
import {
    LEVELS_URL,
    MARKET_SEARCH_URL,
    MARKET_URL,
    THEMES_URL, TYPES_URL
} from "../../constants";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {AuthContext} from "../contexts/AuthContext";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Delete from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import TitleIcon from '@material-ui/icons/Title';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {InlineTex} from "react-tex";
import PreviewImage from "../common/PreviewImage";
import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";
import ConfirmDialog from "../common/ConfirmDialog";
import {convertCarriageReturns, getLetter} from "../../utils";
import Pagination from "@material-ui/lab/Pagination";
import Badge from "@material-ui/core/Badge";
import MarketQuizzContext from "../contexts/MarketQuizzContext";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {useHistory} from "react-router-dom";
import MarketEditDialog from "./MarketEditDialog";
import {Link as RouterLink} from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from 'react-router-dom';
import ConfigContext from "../contexts/ConfigContext";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    chip: {
        marginRight: 1 + "em"
    },
    selectionPopper: {
        padding: 2 + "em",
        width: '400px',
        minHeight: '200px'
    },
    filtersGridItem: {},
    searchGridItem: {}
}));

export default function Market(props) {

    const classes = useStyles();

    const auth = useContext(AuthContext);
    const [keyword, setKeyword] = useState("");
    const [theme, setTheme] = useState("0");
    const [level, setLevel] = useState("0");
    const [type, setType] = useState("0");
    const [themesList, setThemesList] = useState([]);
    const [levelsList, setLevelsList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [resultsList, setResultsList] = useState([]);
    const [loading, setLoading] = React.useState(true);
    const [dialogApproveOpen, setDialogApproveOpen] = React.useState(false);
    const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
    const [dialogEditOpen, setDialogEditOpen] = React.useState(false);
    const [currentQuestion, setCurrentQuestion] = React.useState();
    const [onlyActiveQuestions, setOnlyActiveQuestions] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [popperOpen, setPopperOpen] = React.useState(false);

    const axiosInstance = useAxiosInstance(props);
    const marketQuizzContext = useContext(MarketQuizzContext);
    let history = useHistory();
    const {config} = useContext(ConfigContext);

    const showSelectionRef = useRef(null);

    const deleteQuestion = (confirm) => {
        if (confirm) {
            axiosInstance.delete(MARKET_URL + "/" + currentQuestion.id).then(res => {
                search(0);
            });
        }
        setDialogDeleteOpen(false);

    };

    const approveQuestion = (confirm) => {
        if (confirm) {
            axiosInstance.patch(MARKET_URL + "/" + currentQuestion.id, {"status": 1}).then(res => {
                search(0);
            });
        }
        setDialogApproveOpen(false);
    };

    const removeQuestion = (index) => {
        marketQuizzContext.dispatch({
            type: "REMOVE",
            index: index
        });
    };

    const addQuestion = (index) => {
        let selectedQuestion = resultsList[index];
        if (marketQuizzContext.state.questions.filter(item => {
            return item.id === selectedQuestion.id
        }).length === 0) {
            marketQuizzContext.dispatch({
                type: "ADD",
                question: {
                    id: selectedQuestion.id,
                    description: selectedQuestion.description,
                    text: selectedQuestion.text,
                    answer1: selectedQuestion.answer1,
                    answer2: selectedQuestion.answer2,
                    answer3: selectedQuestion.answer3,
                    answer4: selectedQuestion.answer4,
                    correctAnswer: selectedQuestion.correct_answer,
                    smallImageUrl: selectedQuestion.smallImageUrl,
                    bigImageUrl: selectedQuestion.bigImageUrl,
                }
            });
        }
        showSelection(true);
    };

    const addToQuizz = () => {
        history.push("/create_quizz", {importFromMarket: true});
    };

    const showSelection = (event) => {
        setPopperOpen(true);
        showSelectionRef.current.scrollIntoView();
    };

    const downloadFile = async (question) => {
        let authHeaders = {};
        authHeaders["Authorization"] = "JWT " + localStorage.getItem('access_token');

        axiosInstance.get(config.FRONT_BACK_BASE_URL + "latex/export/" + question.id).then(response => {


            let regex_leading_number = /^([0-9]*).*/
            let match_theme = regex_leading_number.exec(question.theme);
            let match_level = regex_leading_number.exec(question.level);
            let match_type = regex_leading_number.exec(question.type);

            const blob = new Blob([response.data], {type: 'text/plain'});
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = ((match_theme.length > 0) ? match_theme[1] : "") + ((match_level.length > 0) ? match_level[1] : "") + ((match_type.length > 0) ? match_type[1] : "") + "_" + question.id + ".tex";
            link.href = href;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });

    };

    const confirmAction = (question, action) => {
        setCurrentQuestion(question);
        action(true);
    };

    const editQuestion = (question) => {
        setCurrentQuestion(question);
        setDialogEditOpen(true);
    };

    const duplicateQuestion = (question) => {
        const data = {
            "id": question.id
        }
        axiosInstance.post('/api/market/quizz/_duplicate', data)
            .then(response => {
                search(0);
            })
            .catch(error => {

            });
    };

    const exportQuestion = (question) => {

    };

    const closeEditDialog = () => {
        setDialogEditOpen(false);
        search(currentPage);
    };

    const getThemes = () => {
        let themes = [];
        axiosInstance.get(THEMES_URL).then(res => {
            res.data.map((theme) => {
                themes.push(
                    {
                        "id": theme.id,
                        "name": theme.name,
                    }
                );

            });
            setThemesList(themes);
        });
    };

    const getLevels = () => {
        let levels = [];
        axiosInstance.get(LEVELS_URL).then(res => {
            res.data.map((level) => {
                levels.push(
                    {
                        "id": level.id,
                        "name": level.name,
                    }
                );

            });
            setLevelsList(levels);
        });
    };

    const getTypes = () => {
        let types = [];
        axiosInstance.get(TYPES_URL).then(res => {
            res.data.map((type) => {
                types.push(
                    {
                        "id": type.id,
                        "name": type.name,
                    }
                );

            });
            setTypesList(types);
        });
    };

    useEffect(() => {
        getThemes();
        getLevels();
        getTypes();
    }, []);


    const search = (page) => {
        setLoading(true);
        let requestData = {"active": onlyActiveQuestions};
        if (theme && theme !== "0") requestData["theme"] = theme;
        if (level && level !== "0") requestData["level"] = level;
        if (type && type !== "0") requestData["type"] = type;
        if (keyword) requestData["keyword"] = keyword;
        requestData["page"] = page;
        axiosInstance.post(MARKET_SEARCH_URL, requestData).then(res => {
            setCurrentPage(page);
            setResultsList(res.data.results);
            setCount(res.data.count);
            setLoading(false);
        });
    };

    useEffect(() => {
        search(0);
    }, [theme, level, type, keyword, onlyActiveQuestions]);

    const onPageChange = (event, value) => {
        search(value - 1);
    };

    return (
        <Container maxWidth="lg" component="main" style={{marginTop: 2 + "em"}}>

            <Grid container spacing={5}>
                <Grid className={classes.filtersGridItem} direction={"row"} item md={4} lg={2}>
                    <Typography variant="h6" gutterBottom style={{marginBottom: 1 + "em"}}>
                        Filters
                    </Typography>
                    <InputLabel id="theme-select-label">Theme</InputLabel>
                    <Select
                        labelId="theme-select-label"
                        value={theme}
                        variant="outlined"
                        defaultValue=""
                        fullWidth
                        onChange={(event) => setTheme(event.target.value)}
                    >
                        <MenuItem key="0" value="0">Choose a theme</MenuItem>
                        {
                            themesList.map((theme, index) => (
                                <MenuItem key={theme.id} value={theme.id}>{theme.name}</MenuItem>
                            ))
                        }

                    </Select>

                    <InputLabel id="level-select-label" style={{marginTop: 2 + "em"}}>Level</InputLabel>
                    <Select
                        labelId="level-select-label"
                        value={level}
                        variant="outlined"
                        defaultValue=""
                        fullWidth
                        onChange={(event) => setLevel(event.target.value)}
                    >
                        <MenuItem key="0" value="0">Choose a level</MenuItem>
                        {
                            levelsList.map((level, index) => (
                                <MenuItem key={level.id} value={level.id}>{level.name}</MenuItem>
                            ))
                        }

                    </Select>

                    <InputLabel id="type-select-label" style={{marginTop: 2 + "em"}}>Type</InputLabel>
                    <Select
                        labelId="type-select-label"
                        value={type}
                        variant="outlined"
                        defaultValue=""
                        fullWidth
                        onChange={(event) => setType(event.target.value)}
                    >
                        <MenuItem key="0" value="0">Choose a type</MenuItem>
                        {
                            typesList.map((type, index) => (
                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                            ))
                        }

                    </Select>

                    {
                        (auth.state.user && auth.state.user.is_staff) ?
                            <React.Fragment>
                                <FormControlLabel style={{marginTop: 2 + "em"}}
                                                  control={<Switch checked={onlyActiveQuestions}
                                                                   onChange={event => setOnlyActiveQuestions(event.target.checked)}/>}
                                                  label={onlyActiveQuestions ? "Approved questions" : "Non-approved questions"}
                                />
                            </React.Fragment>
                            :
                            <React.Fragment/>
                    }


                    <Typography variant="h6" gutterBottom style={{marginTop: 4 + "em", marginBottom: 1 + "em"}}>
                        Contribute
                    </Typography>
                    <Button component={RouterLink} variant="contained" size="small" color="primary"
                            to="/market/propose">
                        Propose a question
                    </Button>

                </Grid>
                <Grid className={classes.searchGridItem} item md={8} lg={10}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} lg={8}>
                            <Paper component="form" className={classes.root}>

                                <InputBase
                                    value={keyword}
                                    className={classes.input}
                                    placeholder="Search for questions"
                                    inputProps={{'aria-label': 'search questions'}}
                                    onChange={event => setKeyword(event.target.value)}
                                />
                                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                    <SearchIcon/>
                                </IconButton>

                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <div style={{textAlign: "right"}}>
                                {
                                    (marketQuizzContext.state.questions.length > 0) ?
                                        <IconButton onClick={showSelection}>
                                            <Badge badgeContent={marketQuizzContext.state.questions.length}
                                                   color="primary" style={{marginRight: 1 + "em"}}>
                                                <PlaylistAddIcon/>
                                            </Badge>
                                        </IconButton> : null
                                }
                                {count} {((!onlyActiveQuestions) ? "uncertified" : "certified")} questions
                            </div>
                        </Grid>
                    </Grid>
                    {
                        (loading) ?
                            <div>
                                <CircularProgress/>
                            </div> :
                            (resultsList.length === 0) ? (
                                <Typography variant="h6" gutterBottom style={{marginTop: 2 + "em"}}>
                                    No question found
                                </Typography>
                            ) : (

                                resultsList.map((result, index) => (
                                    <Card elevation={3} style={{marginTop: 2 + "em"}}>
                                        <CardHeader title={result.description} action={
                                            <React.Fragment>
                                                {
                                                    (auth.state.user && auth.state.user.is_staff) ?
                                                        <React.Fragment>
                                                            <Tooltip title="Duplicate"
                                                                     onClick={() => duplicateQuestion(result)}>
                                                                <IconButton aria-label="duplicate">
                                                                    <FileCopyIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Export question" aria-label="export">
                                                                <a href="#">
                                                                    <IconButton aria-label="settings"
                                                                                onClick={() => downloadFile(result)}>
                                                                        <TitleIcon/>
                                                                    </IconButton>
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip title="Edit question" aria-label="edit">
                                                                <IconButton aria-label="settings" t
                                                                            onClick={() => editQuestion(result)}>
                                                                    <EditIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete question" aria-label="delete">
                                                                <IconButton aria-label="settings"
                                                                            onClick={() => confirmAction(result, setDialogDeleteOpen)}>
                                                                    <Delete/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {
                                                                (!onlyActiveQuestions) ?
                                                                    <Tooltip title="Approve question"
                                                                             aria-label="approve">
                                                                        <IconButton aria-label="settings"
                                                                                    onClick={() => confirmAction(result, setDialogApproveOpen)}>
                                                                            <CheckIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    :
                                                                    null
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        null
                                                }
                                                <Tooltip title="Add question" aria-label="add">
                                                    <IconButton aria-label="settings"
                                                                onClick={() => addQuestion(index)}>
                                                        <PlaylistAddIcon/>
                                                    </IconButton>
                                                </Tooltip>

                                            </React.Fragment>

                                        }>

                                        </CardHeader>
                                        <CardContent>
                                            <Typography variant="body1" color="textPrimary" component="p">
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        {
                                                            (result.theme) ?
                                                                <Chip style={{marginBottom: 1 + "em"}}
                                                                      className={classes.chip} label={result.theme}/>
                                                                : null
                                                        }
                                                        {
                                                            (result.level) ?
                                                                <Chip style={{marginBottom: 1 + "em"}}
                                                                      className={classes.chip} label={result.level}/>
                                                                : null
                                                        }
                                                        {
                                                            (result.type) ?
                                                                <Chip style={{marginBottom: 1 + "em"}}
                                                                      className={classes.chip} label={result.type}/>
                                                                : null
                                                        }
                                                        {
                                                            (auth.state.user && auth.state.user.is_staff) ?
                                                                <p>
                                                                    Tags: {result.tags}<br/>
                                                                    Submitted
                                                                    by: {result.owner.first_name} {result.owner.last_name}
                                                                </p>
                                                                : <React.Fragment/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InlineTex texContent={convertCarriageReturns(result.text)}
                                                                   texSeperator="${1}"/>
                                                    </Grid>

                                                    <Grid item sm={8}>
                                                        {
                                                            [1, 2, 3, 4].map(q => (
                                                                (result["answer" + q]) ?
                                                                    <div>
                                                                        {getLetter(q - 1)}.&nbsp;
                                                                        <Radio
                                                                            disabled="disabled"
                                                                            value=""
                                                                            checked={result["correct_answer"] === q ? "checked" : ""}
                                                                            name="answer"
                                                                        />

                                                                        <InlineTex
                                                                            texContent={convertCarriageReturns(result["answer" + q])}
                                                                            texSeperator="${1}"/>
                                                                    </div> :
                                                                    <React.Fragment/>
                                                            ))
                                                        }
                                                    </Grid>
                                                    <Grid item sm={3}>
                                                        <PreviewImage smallImageUrl={result.smallImageUrl}
                                                                      bigImageUrl={result.bigImageUrl}/>
                                                    </Grid>

                                                    {
                                                        result.explanation || result.explanationSmallImageUrl ? (
                                                            <React.Fragment>
                                                                <Grid item sm={8}>
                                                                    <h3>Explanation</h3>
                                                                    <InlineTex
                                                                        texContent={convertCarriageReturns(result.explanation)}
                                                                        texSeperator="${1}"/>
                                                                </Grid>
                                                                <Grid item sm={3}>
                                                                    <PreviewImage
                                                                        smallImageUrl={result.explanationSmallImageUrl}
                                                                        bigImageUrl={result.explanationBigImageUrl}/>
                                                                </Grid>
                                                            </React.Fragment>
                                                        ) : null
                                                    }
                                                </Grid>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))
                            )
                    }
                    <Grid item xs={12} style={{marginTop: 1 + "em"}}>
                        <Pagination variant="outlined" count={Math.ceil(count / 10)} defaultPage={currentPage + 1}
                                    page={currentPage + 1} boundaryCount={2} onChange={onPageChange}/>
                    </Grid>
                </Grid>


            </Grid>
            <ConfirmDialog open={dialogApproveOpen} title="Are you sure?" onClose={approveQuestion}>
                By clicking yes, this question will be approved.
            </ConfirmDialog>
            <ConfirmDialog open={dialogDeleteOpen} title="Are you sure?" onClose={deleteQuestion}>
                By clicking yes, this question will be deleted.
            </ConfirmDialog>
            <MarketEditDialog open={dialogEditOpen} id={currentQuestion ? currentQuestion.id : ""}
                              onClose={closeEditDialog}/>
            <Popper id="selection-popper" open={popperOpen} anchorEl={showSelectionRef.current}>
                <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
                    <Paper className={classes.selectionPopper}>
                        <Typography variant="h6" gutterBottom style={{marginBottom: 1 + "em"}}>
                            Selected questions
                        </Typography>
                        <List dense={true}>
                            {
                                marketQuizzContext.state.questions.map((question, index) => {
                                    return (<ListItem>
                                        <ListItemText primary={question.description}>

                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete">
                                                <DeleteIcon onClick={() => removeQuestion(index)}/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>)
                                })
                            }
                        </List>
                        <Button variant="contained" size="small" color="primary" onClick={addToQuizz}>Add to my
                            quizz</Button>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </Container>

    );
}