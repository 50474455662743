import React, {useContext, useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import {format, parseISO} from "date-fns"

import TextField from "@material-ui/core/TextField";
import {
    IMPORT_QUIZZ_URL,
    MARKET_SEARCH_URL,
    QUIZZ_SEARCH_URL,
    THEMES_URL,
    TYPES_URL,
    REMOVE_PUBLIC_QUIZZ_URL
} from "../../../constants";
import useAxiosInstance from "../../../api/axiosApi";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Pagination from "@material-ui/lab/Pagination";
import {AuthContext} from "../../contexts/AuthContext";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import CardHeader from "@material-ui/core/CardHeader";
import ConfirmDialog from "../../common/ConfirmDialog";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import StatusChip from "../../common/StatusChip";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {Remove} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    chip: {
        marginRight: 1 + "em"
    },
    filtersGridItem: {
        order: 1,
        [theme.breakpoints.down('md')]: {
            order: 2,
        },
    },
    searchGridItem: {
        order: 2,
        [theme.breakpoints.down('md')]: {
            order: 1,
        },
    }
}));

export default function ImportPublicQuizzDialog(props) {

    const classes = useStyles();

    const auth = useContext(AuthContext);
    const [keyword, setKeyword] = useState("");
    const [theme, setTheme] = useState("0");
    const [type, setType] = useState("0");
    const [themesList, setThemesList] = useState([]);
    const [resultsList, setResultsList] = useState([]);
    const [loading, setLoading] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [selected, setSelected] = React.useState([]);


    const showSelectionRef = useRef(null);

    const axiosInstance = useAxiosInstance(props);
    let history = useHistory();

    useEffect(() => {
    }, []);

    const confirmAction = (quizz, action) => {
        //setCurrentQuizz(quizz);
        action(true);
    };

    const disableQuizzSharing = () => {

    };

    const search = (page) => {
        setLoading(true);
        let requestData = {};
        if (theme && theme !== "0") requestData["theme"] = theme;
        if (type && type !== "0") requestData["type"] = type;
        if (keyword) requestData["keyword"] = keyword;
        requestData["page"] = page;
        axiosInstance.post(QUIZZ_SEARCH_URL, requestData).then(res => {
            if (res && res.data) {
                setCurrentPage(page);
                setResultsList(res.data.results);
                setCount(res.data.count);
            }
            setLoading(false);
        });
    };

    const deleteQuizz = (id) => {
        axiosInstance.post(REMOVE_PUBLIC_QUIZZ_URL + "/" + id).then(res => {
            search(0);
        }).catch(err => {
            console.log("Cannot remove quizz.");
        });
    };

    const importQuizz = () => {
        axiosInstance.post(IMPORT_QUIZZ_URL + "/" + selected).then(res => {
            props.onSuccess();
        }).catch(err => {
            console.log("Cannot copy quizz.");
        });
    };

    useEffect(() => {
        search(0);
    }, [theme, type, keyword, props.open]);

    const onPageChange = (event, value) => {
        search(value - 1);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                fullScreen
                onClose={() => props.onClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Import a public quizz</DialogTitle>
                <DialogContent>
                    <Grid container spacing={5}>
                        <Grid className={classes.filtersGridItem} item md={12} lg={2}>
                            <Typography variant="h6" gutterBottom style={{marginBottom: 1 + "em"}}>
                                Filters
                            </Typography>
                        </Grid>
                        <Grid className={classes.searchGridItem} item md={12} lg={10}>
                            <Grid container spacing={5}>
                                <Grid item xs={8}>
                                    <Paper component="form" className={classes.root}>

                                        <InputBase
                                            value={keyword}
                                            className={classes.input}
                                            placeholder="Search for quizzes"
                                            inputProps={{'aria-label': 'search questions'}}
                                            onChange={event => setKeyword(event.target.value)}
                                        />
                                        <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                            <SearchIcon/>
                                        </IconButton>

                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>

                                    <div ref={showSelectionRef} style={{textAlign: "right", marginTop: 1 + "em"}}>
                                        {count} uncertified quizzes
                                    </div>
                                </Grid>
                            </Grid>
                            {
                                (loading) ?
                                    <div>
                                        <CircularProgress/>
                                    </div> :
                                    (resultsList.length === 0) ? (
                                        <Typography variant="h6" gutterBottom style={{marginTop: 2 + "em"}}>
                                            No quizz found
                                        </Typography>
                                    ) : (

                                        <Table
                                            className={classes.table}
                                            aria-labelledby="tableTitle"
                                            size={'medium'}
                                            aria-label="enhanced table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">Title</TableCell>
                                                    <TableCell align="left">Description</TableCell>
                                                    <TableCell align="left">Duration</TableCell>
                                                    <TableCell align="left">Creation date</TableCell>
                                                    {
                                                        (auth.state.user && auth.state.user.is_staff) ?
                                                            <TableCell align="left"></TableCell>
                                                            : null
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {resultsList.map((result, index) => {
                                                    return (

                                                        <TableRow
                                                            hover
                                                            onClick={(event) => setSelected(result.share_code)}
                                                            role="checkbox"
                                                            aria-checked={result.share_code === selected}
                                                            tabIndex={-1}
                                                            key={result.share_code}
                                                            selected={result.share_code === selected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={result.share_code === selected}
                                                                    inputProps={{'aria-labelledby': result.share_code}}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">{result.title}</TableCell>
                                                            <TableCell align="left">{result.description}</TableCell>
                                                            <TableCell align="left">{result.duration}</TableCell>

                                                            <TableCell
                                                                align="left">{format(parseISO(result.created_date), "dd.MM.yyyy")}
                                                            </TableCell>
                                                            {
                                                                (auth.state.user && auth.state.user.is_staff) ?
                                                                    <TableCell>
                                                                        <IconButton edge="end" aria-label="delete">
                                                                            <DeleteIcon
                                                                                onClick={() => deleteQuizz(result.code)}/>
                                                                        </IconButton>
                                                                    </TableCell>
                                                                    : null
                                                            }
                                                        </TableRow>


                                                    )
                                                })}
                                            </TableBody>
                                        </Table>

                                    )
                            }
                            <Grid item xs={12} style={{marginTop: 1 + "em"}}>
                                <Pagination variant="outlined" count={Math.ceil(count / 10)}
                                            defaultPage={currentPage + 1} page={currentPage + 1} boundaryCount={2}
                                            onChange={onPageChange}/>
                            </Grid>
                        </Grid>


                    </Grid>
                    <ConfirmDialog open={dialogDeleteOpen} title="Are you sure?" onClose={disableQuizzSharing}>
                        By clicking yes, this quizz will not be public anymore.
                    </ConfirmDialog>
                </DialogContent>
                <DialogActions>
                    <Button onClick={importQuizz} color="primary">
                        Import
                    </Button>
                    <Button onClick={() => props.onClose(false)} color="secondary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
