import React, {Component, useContext, useEffect, useState} from "react";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import {makeStyles} from '@material-ui/core/styles';
import {addDays, parse} from 'date-fns'

import useAxiosInstance from "../../../api/axiosApi";
import {differenceInSeconds, parseISO} from "date-fns";
import LatexInput from "../../common/LatexInput";
import UploadImage from "../../common/UploadImage";
import {InlineTex} from "react-tex";
import PreviewImage from "../../common/PreviewImage";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Box from "@material-ui/core/Box";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {convertCarriageReturns, shuffleArray} from "../../../utils";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import {labels} from "../../../data/quizz.js"
import QuestionsButtons from "../../common/QuestionsButtons";
import {ButtonBase, Hidden} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Link from "@material-ui/core/Link";
import {AuthContext} from "../../contexts/AuthContext";
import Countdown from "react-countdown";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,

    paper: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    cardHeader: {
        backgroundColor: theme.palette.grey[200],
        fontSize: '24px',
    },
    btn: {
        marginTop: 2 + "em",
        marginBottom: 2 + "em"
    },
    questionBtn: {
        minWidth: 40 + "px",
        marginLeft: 1 + "em",
        marginBottom: 1 + "em"
    },
    responseBtn: {
        position: 'relative',
        display: "block",
        width: "100%",
        minHeight: "50px",
        padding: 10,
        border: "1px solid #000",
        borderRadius: 10,
        backgroundColor: "rgba(243,146,0,0.3)",
        marginBottom: 10
    },
    responseBtnSelected: {
        position: 'relative',
        display: "block",
        width: "100%",
        minHeight: "50px",
        padding: 10,
        border: "1px solid #000",
        borderRadius: 10,
        backgroundColor: "rgba(243,146,0,1)",
        marginBottom: 10
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    time: {
        fontWeight: "bold"
    },
    questionTitle: {
        paddingTop: "10px",
        marginBottom: "20px",
        textAlign: "center"
    },
    questionsNavigate: {
        marginTop: "50px",
        marginLeft: "20px"
    }


}));

const parseDateFormatBackend = 'yyyy-MM-dd\'T\'H:mm:ss.SSSSSS\'Z\'';


export default function RunQuizz(props) {

    let history = useHistory();
    const auth = useContext(AuthContext);

    const [quizz, setQuizz] = useState({});
    const [questions, setQuestions] = useState([]);
    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(0);
    const [answers, setAnswers] = useState({});
    const [endTime, setEndTime] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [endQuizzOpen, setEndQuizzOpen] = useState(false);
    const [answersOrder, setAnswersOrder] = useState([]);

    const axiosInstance = useAxiosInstance(props);


    useEffect(() => {

        //Get quizz
        axiosInstance.get('/api/quizz/_start/' + props.match.params.code, {headers: {"Authorization": null}}).then(response => {
            if (response.status === 200 && response.data.questions) {
                setQuizz(response.data);

                let questionsArray = response.data.questions;
                if (response.data.random_questions) {
                    questionsArray = shuffleArray(questionsArray);
                }

                //shuffle answers
                let answersOrder = [];
                for (let i = 0; i < response.data.questions.length; i++) {
                    let answer_indices = [1, 2, 3, 4];
                    if (response.data.random_answers) answer_indices = shuffleArray(answer_indices);
                    answersOrder[i] = answer_indices;
                }
                setAnswersOrder(answersOrder);

                setQuestions(questionsArray);
                //get session
                let session_id = localStorage.getItem("quizz_" + response.data.code);
                let session_id_params = "";
                if (session_id) session_id_params = "?session_id=" + session_id;
                else {
                    //a session should exist
                    history.push("/start_quizz/" + props.match.params.code);
                }
                axiosInstance.get('/api/quizz/response/_start/' + response.data.code + session_id_params, {headers: {"Authorization": null}}).then(res => {

                    if (res.data.response.finished) {
                        history.push("/end_quizz/" + response.data.code);
                        return;
                    }

                    const startTime = parse(res.data.response.start_date, parseDateFormatBackend, new Date()).getTime() / 1000;
                    const nowTime = parse(res.data.now_date, parseDateFormatBackend, new Date()).getTime();
                    const endTime = parse(res.data.response.end_date, parseDateFormatBackend, new Date()).getTime();
                    // get the precise duration based on server time to determine remaining duration
                    setEndTime(Date.now() + endTime - nowTime);
                    //get already answered questions
                    let answers = {};
                    res.data.response.questions.forEach((question) => {
                        answers[question["question"]] = question["answer"];
                    });
                    setAnswers(answers);

                    setIsLoading(false);
                });

            }
        });
    }, []);

    const previousQuestion = (event, index) => {
       setStep(step - 1);
    };

    const nextQuestion = (event, index) => {
        setStep(step + 1);
    };

    const toQuestion = (index) => {
        setStep(index);
    };

    const numberAnsweredQuestions = () => {
        let number = 0;

        Object.values(answers).forEach((item) => {
            if (item >= 0) number++;
        });

        return number;
    };

    const submitQuizz = (event) => {

        let params = {"Authorization": null};
        let session_id = localStorage.getItem("quizz_" + quizz.code);
        let data = {
            key: questions[step].id,
            answer: answers[questions[step].id]
        };
        try {
            axiosInstance.post('/api/quizz/response/_end/' + quizz.code + "/" + session_id, data, params).then(response => {
                if (response.status === 201 && response.data) {
                    history.push("/end_quizz/" + quizz.code);
                }
            });
            //redirect to quizz
        } catch (error) {

        }
    };

    const handleChange = (index) => {
        const newAnswers = {...answers};
        newAnswers[questions[step].id] = index;
        setAnswers(newAnswers);
        let params = {};
        let session_id = localStorage.getItem("quizz_" + quizz.code);
        if (session_id) {
            params = {"Authorization": null, "params": {"session_id": session_id}}
        }
        let data = {
            key: questions[step].id,
            answer: index
        };
        axiosInstance.post('/api/quizz/response/_save/' + quizz.code, data, params).then(response => {
            if (response.status === 201) {
                if (questions && step < questions.length - 1) nextQuestion();
            }
        }).catch(err => {
            setEndQuizzOpen(true);
        });
    };

    const currentAnswers = (step) => {
        let content = [];
        let answer_indices = answersOrder[step];
        let index = 0;
        answer_indices.forEach((i) => {
            if (questions[step]["answer" + i]) {
                content.push(
                    <ButtonBase
                        className={(answers[questions[step].id] === i) ? classes.responseBtnSelected : classes.responseBtn}
                        key={i}
                        onClick={() => handleChange(i)}>
          <span className={classes.imageButton}>

                        <InlineTex texContent={convertCarriageReturns(questions[step]["answer" + i])}
                                   texSeperator="${1}"/>
          </span>
                    </ButtonBase>
                );
                index++;
            }
        });
        return (
            <ul>
                {content}
            </ul>
        );
    };


    const currentQuestion = (step) => {
        if (questions) {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.questionTitle}>
                            <Typography gutterBottom variant="h6" component="h2">
                                Question {step + 1} / {questions.length}
                            </Typography>
                        </div>
                        <InlineTex texContent={convertCarriageReturns(questions[step].text)} texSeperator="${1}"/>
                        <br/><br/>
                        <PreviewImage smallImageUrl={questions[step].smallImageUrl}
                                      bigImageUrl={questions[step].bigImageUrl}/>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        {currentAnswers(step)}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        {
                            step > 0 ? (
                                <Button className={classes.btn} variant="contained" color="secondary"
                                        onClick={previousQuestion}>Previous</Button>
                            ) : null
                        }

                        {
                            questions && step < questions.length - 1 ? (
                                <Button onClick={nextQuestion} className={classes.btn} variant="contained"
                                        color="primary"
                                        style={{float: "right"}}
                                >
                                    Next
                                </Button>
                            ) : null
                        }

                        {
                            questions && step === questions.length - 1 ? (
                                <Button onClick={submitQuizz} className={classes.btn} variant="contained"
                                        color="primary"
                                        style={{float: "right"}}
                                >
                                    End the quizz
                                </Button>
                            ) : null
                        }

                    </Grid>
                </Grid>
            )
        } else return (<div/>)
    };

    const classes = useStyles();

    if (isLoading) {
        return (
            <Container maxWidth="lg" component="main" style={{marginTop: 2 + "em"}}>
                <Box hidden={props.hidden}>Loading...</Box>
            </Container>
        );
    }

    const Completionist = () => <span>Time is over!</span>;

// Renderer callback with condition
    const renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state
            return <Completionist/>;
        } else {
            // Render a countdown
            return <span className={classes.time}>{hours}h {minutes}m {seconds}s</span>;
        }
    };

    return (
        <AuthContext.Consumer>
            {context => (
                <React.Fragment>
                    <AppBar position="fixed" color="default" elevation={0} className={classes.appBar}>
                        <Toolbar className={classes.toolbar}>
                            <Link color="inherit" className={classes.toolbarTitle}>
                                <Link component={RouterLink} className="link-black" to="/"><img src="/logo_texquizz.png"
                                                                                                height="30"/></Link>

                            </Link>

                            <nav>
                                <Countdown
                                    date={endTime}
                                    renderer={renderer}
                                    daysInHours={true}
                                    zeroPadTime={2}
                                />

                            </nav>

                        </Toolbar>
                    </AppBar>
                    <Container maxWidth="sm" component="main">
                        <div className={classes.offset}/>
                        <Grid container justify="space-between">
                            <Grid item xs={12} sm={12} md={8} direction={"row"}>
                                {currentQuestion(step)}
                            </Grid>

                            <Hidden smDown>
                                <Grid item md={4} alignItems={"center"}>
                                    <div className={classes.questionsNavigate}>
                                        <QuestionsButtons questions={questions} answers={answers}
                                                          toQuestion={toQuestion}/>
                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>

                        <Dialog
                            open={endQuizzOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Time has ended</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    You cannot modify your questions anymore as the time as ended. Please click on
                                    the
                                    button here
                                    below to end the quizz.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={submitQuizz} color="primary">
                                    End the quizz
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Container>
                </React.Fragment>
            )}
        </AuthContext.Consumer>
    );

}

