import React, {Component, Fragment, useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import ProgressBar from "../../common/ProgressBar";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Chart from "react-google-charts";
import {labels} from "../../../data/quizz.js"
import {InlineTex} from "react-tex";
import {convertCarriageReturns} from "../../../utils";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import PreviewImage from "../../common/PreviewImage";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles({
    table: {

    },
});


export default function QuizzAnswersReport(props) {

    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    const handleClose = (value) => {
        setOpen(false);
    };

    const openQuestionDetailDialog = (index) => {
        setSelectedIndex(index);
        setOpen(true);

    };

    const currentAnswers = (question) => {
        let content = [];
        for (let i = 1; i <= 4; i++) {
            if (question["answer"+i])
                content.push(
                    <li key={i}>
                        {labels[i-1]}.&nbsp;<Radio
                        checked={question.correct_answer === i}
                        disabled
                        value={i}
                        name="answer"
                    />
                        <InlineTex texContent={convertCarriageReturns(question["answer"+i])} texSeperator="${1}"/>
                    </li>
                )
        }
        return (
            <ul>
                {content}
            </ul>
        );
    };

    const currentQuestion = (question) => {
        const questionText = props.data[selectedIndex]["question"];
        return (
            <Grid container spacing={3}>
                <Grid item sm={12}>

                    {questionText}: <InlineTex texContent={convertCarriageReturns(question.text)} texSeperator="${1}"/>
                </Grid>
                <Grid item sm={9}>
                    {currentAnswers(question)}
                </Grid>
                <Grid item sm={3}>
                    <PreviewImage smallImageUrl={question.smallImageUrl} bigImageUrl={question.bigImageUrl} />
                </Grid>
            </Grid>
        )
    };



    const getData = () => {
        let data = [[
            'Element',
            'Density',
            {role: 'style'},
            {
                sourceColumn: 0,
                role: 'annotation',
                type: 'string',
                calc: 'stringify',
            },
        ]];
        const colors = ["E95931", "28a12c"];
        for (let i=1; i<=4; i++) {
            if (props.data[selectedIndex]) {
                if (props.data[selectedIndex]["responses"] && props.data[selectedIndex]["responses"][i]) {
                    const percentValue = Math.round(props.data[selectedIndex]["responses"][i] / props.data[selectedIndex]["totalResponses"]*100);
                    data.push([labels[i-1], percentValue, i==props.data[selectedIndex]["correctAnswer"] ? colors[1] : colors[0], percentValue + "%"]);
                } else data.push([labels[i-1], 0, colors[0], "0%"]);
            }
        }
        return data;
    };

    const classes = useStyles();

    return (
        <React.Fragment>
            <div>
                {
                    props.data.map((row, index) => (
                        <div key={index} style={{float: "left", marginRight: 1 + "em"}}>
                            <Link href="#" onClick={() => openQuestionDetailDialog(index)}>
                                <ProgressBar bgColor={"#f39200"} fillColor={"#28a12c"} completed={row.percentage}
                                             label={row.question} width={60}/>
                            </Link>
                        </div>
                    ))
                }
                <div style={{clear:"both"}}></div>
            </div>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="alert-dialog-title">Answers distribution</DialogTitle>
                <DialogContent>
                    <Typography variant="h5" component="h5">
                        {props.quizz.description}
                    </Typography>

                    {(selectedIndex >= 0) ? currentQuestion(props.quizz.questions[selectedIndex]) : null}

                    {
                        (selectedIndex >= 0) ?
                            <Chart

                                width={'600px'}
                                height={'300px'}
                                chartType="ColumnChart"
                                loader={<div>Loading Chart</div>}
                                data={getData()}
                                options={{
                                    animation: {
                                        easing: "linear",
                                        duration: 500,
                                        startup: true
                                    },
                                    legend: {position: 'none'},
                                    enableInteractivity: false,
                                    bar: {groupWidth: '80%'},
                                    vAxes: {
                                        0: {title: 'Answers (%)'}
                                    }
                                }}
                            />
                            : null
                    }
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}