import React, {Component, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useAxiosInstance from "../../../api/axiosApi";
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import { Link as RouterLink } from 'react-router-dom';

export default function Login(props) {

    const axiosInstance = useAxiosInstance(props);

    const auth = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [isInvalidLogin, setIsInvalidLogin] = useState(false);
    const [message, setMessage] = useState("");
    const [forgotPassDialogOpen, setForgotPassDialog] = useState(false);


    useEffect(() => {
        const message = props.match.params.message;
        let messageString = "";
        switch (message) {
            case "activate":
                messageString = "Your account has been activated";
                break;
            case "reset":
                messageString = "Your password has been reset";
                break;
            case "delete":
                messageString = "Your account has been delete";
                break;
        }
        setMessage(messageString);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        axiosInstance.post('/auth/jwt/create/', {
            username: email,
            password: password,
        }).then(response => {
            axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            axiosInstance.get('/auth/users/me/').then(profileResponse => {
                auth.dispatch({
                    type: "LOGIN",
                    user: profileResponse.data,
                    token: response.data.access
                });
                props.history.push("/");
            });
        }).catch(error => {

            if (error.response && error.response.data) {
                setErrors(error.response.data);
                setIsInvalidLogin(true);
            }
        });

    };

    const closeForgotPassDialog = () => {
        setForgotPassDialog(false);
    };

    const useStyles = makeStyles((theme) => ({
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
                width: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            }},
        formElem: {
            marginTop: '1em'
        }
    }));

    const classes = useStyles();

    let messageContent;
    if (isInvalidLogin) {
        messageContent = <Alert elevation={6} variant="filled" severity="error">
            Your login information are not correct or your account is not active.
        </Alert>
    }
    else if (message) {
        messageContent = <Alert elevation={6} variant="filled" severity="success">
            {message}
        </Alert>
    }
    return (

        <Container maxWidth="sm">
            <Paper elevation="3" className={classes.paper}>
                <Typography variant="h5" gutterBottom color="primary">
                    Sign in
                </Typography>
                {messageContent}
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <Grid item xs={12} sm={12} className={classes.formElem}>
                        <TextField
                            name="email"
                            label="Email"
                            value={email}
                            fullWidth
                            onChange={event => setEmail(event.target.value)}
                            error={ errors.email || errors.username ? true : false}
                            helperText={errors.email || errors.username}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.formElem}>
                        <TextField
                            name="password"
                            label="Password"
                            value={password}
                            type="password"
                            fullWidth
                            error={ errors.password ? true : false}
                            helperText={errors.password}
                            onChange={event => setPassword(event.target.value)}
                        />
                    </Grid>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} style={{marginTop: 2 + "em", marginRight: 1+"em"}}>
                        Login
                    </Button>
                    <Grid container justify="space-between" style={{marginTop: 2+"em"}}>
                        <Grid item xs={5} sm={5}>
                            <Link component={RouterLink} to="/signup" color="primary">
                                Create an account
                            </Link>
                        </Grid>
                        <Grid item xs={5} sm={5} style={{textAlign:"right"}}>
                            <Link href="#" onClick={(event) => setForgotPassDialog(true)} color="primary">
                                Forgot your password?
                            </Link>
                        </Grid>
                    </Grid>
                    <ForgotPasswordDialog open={forgotPassDialogOpen} onClose={closeForgotPassDialog}>

                    </ForgotPasswordDialog>
                </form>
            </Paper>
        </Container>
    );

}
