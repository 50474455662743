import StarIcon from "@material-ui/icons/StarBorder";
import React, {Component, useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from "@material-ui/icons/HelpOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {convertCarriageReturns} from "../../utils";
import withStyles from "@material-ui/core/styles/withStyles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary,
    color: '#FFFFFF',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function PlansCards (props) {

    const [selected, setSelected] = useState(0);

    const useStyles = makeStyles((theme) => ({
        '@global': {
            ul: {
                margin: 0,
                padding: 0,
                listStyle: 'none',
            },
        },
        link: {
            margin: theme.spacing(1, 1.5),
        },
        heroContent: {
            padding: theme.spacing(8, 0, 6),
        },
        cardHeader: {
            backgroundColor: theme.palette.grey[200]
        },
        card: {

        },
        cardContent: {
            height: "250px"
        },
        cardPricing: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            marginBottom: theme.spacing(2),
        },
        cardSelected: {
        },
        cardActions: {
            display: "inline"
        }
    }));

    const classes = useStyles();

    const selectCard = (index) => {
        setSelected(index);
        if (props.onChange) props.onChange(index);
    };

    return(
        <Grid container spacing={5} alignItems="flex-end">
            {props.tiers.map((tier, index) => (
                <Grid
                    item
                    key={tier.title}
                    xs={12}
                    sm={12}
                    md={12/props.tiers.length}
                >
                    <Card className={classes[(selected===index) ? "cardSelected" : "card"]}>
                        <CardHeader
                            title={tier.title}
                            titleTypographyProps={{ align: 'center' }}
                            subheaderTypographyProps={{
                                align: 'center',
                            }}
                            className={classes.cardHeader}
                        />
                        <CardContent className={classes.cardContent}>
                            <div className={classes.cardPricing}>
                                <Typography component="h2" variant="h3" color="textPrimary">
                                    CHF {tier.price.CHF}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                    /{tier.period}
                                </Typography>
                            </div>
                            <ul>
                                {tier.description.map((line) => (
                                    <Typography
                                        component="li"
                                        variant="subtitle1"
                                        align="center"
                                        key={line.text}
                                    >
                                        {line.text}
                                        {
                                            line.tooltip ? (
                                                <HtmlTooltip title={
                                                    <React.Fragment>
                                                        <div dangerouslySetInnerHTML={{__html: line.tooltip}}/>
                                                    </React.Fragment>
                                                }
                                                >
                                                    <IconButton size="small">
                                                        <HelpIcon />
                                                    </IconButton>
                                                </HtmlTooltip>
                                            ) : null
                                        }
                                    </Typography>

                                ))}
                            </ul>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}