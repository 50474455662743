import React, {Component, useState} from "react";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import useAxiosInstance from "../api/axiosApi";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            width: 300,
        },
    },
    paper: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    homeTitle: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.black,
        marginBottom: theme.spacing(0),
        backgroundImage: 'url(/banner.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    homeTitleContent: {
        height: "200px",
        [theme.breakpoints.down("sm")]: {
            height:"150px"
        },
        [theme.breakpoints.down("xs")]: {
            height:"100px"
        },
    },
    bannerTitle: {
        color: "#616161",
        textAlign:"right",
        marginTop: "35px"
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
}));

export default function Home (props) {

    const [code, setCode] = useState();
    const [errorMessage, setErrorMessage] = useState(false);

    const axiosInstance = useAxiosInstance(props);

    const handleSubmit = async (event) => {
        event.preventDefault();
        axiosInstance.get('/api/quizz/_start/'+code, {headers:{"Authorization":null}}).then(response => {
            if (response.status === 200 && response.data.questions) {
                props.history.push("/start_quizz/"+response.data.code);
            }
        }).catch(error => {
            let message = error.response.data;
            if (!message) message = "This code is not valid";
            setErrorMessage(message);
        });
    };

    const classes = useStyles();

    return (
        <Container maxWidth="md" component="main" style={{marginTop: 2+"em"}}>

            <Paper className={classes.homeTitle}>
                <Grid container>
                    <Grid className={classes.homeTitle} item xs={12} sm={12}>
                        <div className={classes.homeTitleContent}>

                        </div>

                    </Grid>
                </Grid>
            </Paper>

            <Paper className={classes.paper}>
                <form onSubmit={handleSubmit} className={classes.root}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="code"
                                label="Enter your quizz code to start"
                                value={code}
                                onChange={(event) => setCode(event.target.value)}
                                fullWidth
                                autoComplete=""
                                error={ errorMessage }
                                helperText={errorMessage}
                                style={{width:"200"}}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button variant="contained" color="primary" onClick={handleSubmit} style={{marginTop: 2 + "em"}}>
                                Start the quizz!
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h5" className={classes.bannerTitle}>
                                The best way to create your quizzes easily
                            </Typography>
                        </Grid>

                    </Grid>
                </form>
            </Paper>
        </Container>
    );
}
