import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useAxiosInstance from "../../api/axiosApi";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from 'react-router-dom';
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        }},
    formElem: {
        marginTop: '1.5em'
    }
}));

export default function Profile (props) {

    let history = useHistory();

    const axiosInstance = useAxiosInstance(props);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emailRepeat, setEmailRepeat] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [country, setCountry] = useState("");
    const [countriesDict, setCountriesDict] = useState({});
    const [organizationName, setOrganizationName] = useState("");
    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);


    useEffect(() => {
        loadCountries();
        setCountry("CH");
        if (props.editMode) loadUser();
        else setLoading(false);
    }, []);

    const loadUser = () => {
        try {
            axiosInstance.get('/auth/users/me/').then(response => {
                if (response.data.first_name) setFirstName(response.data.first_name);
                if (response.data.last_name) setLastName(response.data.last_name);
                if (response.data.country_code) setCountry(response.data.country_code);
                if (response.data.organization_name) setOrganizationName(response.data.organization_name);
                setLoading(false);
            });
        }
        catch(error) {

        }
    };

    const loadCountries = async () => {
        const response = await axiosInstance.get('/api/countries/');
        setCountriesDict(response.data);
    };

    const validate = () => {
        let errors = {};
        if (!email && !props.editMode) errors["username"] = "Please enter an email";
        if (!password && !props.editMode) errors["password"] = "Please enter a password";
        if (email!==emailRepeat && !props.editMode) errors["emailRepeat"] = "Emails are not identical";
        if (password!==passwordRepeat && !props.editMode) errors["passwordRepeat"] = "Password are not identical";
        setErrors(errors);
        return !Object.keys(errors).length>0
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            let submitData = {
                first_name: firstName,
                last_name: lastName,
                country_code: country,
                organization_name: organizationName,
            };
            if (!props.editMode) {
                submitData = {...submitData, email: email, username: email, password: password}
            }

            try {
                if (props.editMode) {
                    const response = await axiosInstance.patch('/auth/users/me/', submitData);
                    setIsSuccess(true);
                } else {
                    const response = await axiosInstance.post('/auth/users/', submitData);
                    history.push("/signup_confirmation");

                }
            } catch (error) {
                setErrors(error.response.data);
            }
        }
    };

    const handleDelete = () => {
        setDeleteDialogOpen(true);
    };

    const classes = useStyles();
    let messageContent;
    if (isSuccess) {
        messageContent = <Alert elevation={6} variant="filled" severity="success">
            Your modifications have been saved
        </Alert>
    }

    if (isLoading) {
        return  <Box hidden={props.hidden}>Loading...</Box>;
    }

    return (
        <Box hidden={props.hidden}>
            <form className={classes.form} noValidate autoComplete="off">
                {messageContent}
                <Grid container justify="space-between" style={{marginTop: 1 + "em"}}>


                    <Grid item xs={5} sm={5} className={classes.formElem}>
                        <TextField
                            label="First name"
                            value={firstName}
                            fullWidth
                            onChange={event => setFirstName(event.target.value)}
                            error={ errors.firstName ? true : false}
                            helperText={errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={5} sm={5} className={classes.formElem}>
                        <TextField
                            label="Last name"
                            value={lastName}
                            fullWidth
                            error={ errors.lastName ? true : false}
                            helperText={errors.lastName}
                            onChange={event => setLastName(event.target.value)}
                        />
                    </Grid>
                    {
                        !props.editMode ? (
                            <React.Fragment>
                                <Grid item xs={12} sm={5} className={classes.formElem}>
                                    <TextField
                                        label="Email - Required"
                                        fullWidth
                                        value={email}
                                        error={ errors.username ? true : false}
                                        helperText={errors.username}
                                        onChange={event => setEmail(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5} className={classes.formElem}>
                                    <TextField
                                        label="Email (repeat) - Required"
                                        value={emailRepeat}
                                        fullWidth
                                        error={ errors.emailRepeat ? true : false}
                                        helperText={errors.emailRepeat}
                                        onChange={event => setEmailRepeat(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5} className={classes.formElem}>
                                    <TextField
                                        label="Password - Required"
                                        value={password}
                                        type="password"
                                        error={ errors.password ? true : false}
                                        helperText={errors.password}
                                        fullWidth
                                        onChange={event => setPassword(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5} className={classes.formElem}>
                                    <TextField
                                        label="Password (repeat) - Required"
                                        value={passwordRepeat}
                                        type="password"
                                        fullWidth
                                        error={ errors.passwordRepeat ? true : false}
                                        helperText={errors.passwordRepeat}
                                        onChange={event => setPasswordRepeat(event.target.value)}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : (<React.Fragment></React.Fragment>)
                    }
                    <Grid item xs={12} sm={12} className={classes.formElem}>
                        <InputLabel id="country-label" shrink>Country</InputLabel>
                        <Select labelId="country-label" fullWidth value={country}
                                onChange={(event) => setCountry(event.target.value)}
                        >
                            {
                                Object.keys(countriesDict).map((key, index) => (
                                    <MenuItem key={key} value={key}>{countriesDict[key]}</MenuItem>
                                ))
                            }

                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={5} className={classes.formElem}>
                        <TextField
                            label="Company / Organization Name"
                            value={organizationName}
                            fullWidth
                            onChange={event => setOrganizationName(event.target.value)}
                        />
                    </Grid>

                </Grid>
            </form>
            {
                !props.editMode && (
                    <p><br/>By clicking Sign Up, you agree to our <Link component={RouterLink} className="link-black" to="/terms" target="_new">Terms and Conditions</Link>.</p>
                )
            }
            <Button variant="contained" color="primary" onClick={handleSubmit} style={{marginTop: 1+ "em"}}>
                {props.editMode ? "Modify" : "Sign Up"}
            </Button>
            {
                props.editMode &&
                <Button variant="outlined" size="small" color="secondary" onClick={handleDelete} style={{float:"right", marginTop: 1+ "em"}}>
                    Delete my account
                </Button>
            }
            <ConfirmDeleteDialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}/>
        </Box>
    )


}